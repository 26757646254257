import React, { Fragment } from "react";
import { SetStateAction } from "react";
import { Dispatch } from "react";
import { Button, Modal } from "react-bootstrap";
import AddPaymentModal from "./AddPaymentModal";
import { useConfig } from "../../../configuration/useConfig";

const FailPaymentModal = ({
  open,
  onClose,
  isEnrollment,
}: {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  isEnrollment?: boolean;
}) => {
  const { config } = useConfig();

  const handleClose = async () => {
    onClose(!open);
  };

  return open ? (
    <Fragment>
      <Modal centered show={open} dialogClassName="status-modal">
        <Modal.Header
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="modal-header-form"
        >
          <div className="modal-header-form__exclamation"></div>
          <h2>
            <b>Something Went Wrong</b>
          </h2>
        </Modal.Header>
        <Modal.Body className="modal-body-form">
          <p style={{ textAlign: "left" }}>
            There was an issue verifying your payment method. Please{" "}
            <a href="/billing" className="a-link">
              add a new payment method
            </a>{" "}
            or call Customer Service:{" "}
            <a href={`tel:${config["contact.phoneNumber"]}`} className="a-link">
              {config["contact.phoneNumber"]}
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-form">
          <Button
            bsPrefix="btn font-weight-bold modal-footer-form__close_button"
            style={{ whiteSpace: "nowrap" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            bsPrefix="btn font-weight-bold modal-footer-form__button"
            style={{ whiteSpace: "nowrap" }}
            onClick={
              isEnrollment
                ? handleClose
                : () => {
                    <AddPaymentModal
                      open={false}
                      onAdd={function (): void {
                        throw new Error("Function not implemented.");
                      }}
                      onClose={function (): void {
                        throw new Error("Function not implemented.");
                      }}
                    />;
                  }
            }
          >
            Add New Payment Method
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  ) : null;
};

export default FailPaymentModal;
