import React, { Fragment } from "react";
import { Button, Modal } from "react-bootstrap";

const AddressVerificationModal = ({
  show,
  homeAddress,
  billingAddress,
  onConfirm,
  onCancel,
}: {
  show: boolean;
  homeAddress?: string;
  billingAddress?: string;
  onConfirm: (e: unknown) => void;
  onCancel: (e: unknown) => void;
}) => {
  const getAddressMessage = () => {
    if (homeAddress && !billingAddress) {
      return (
        <Fragment>
          <h4>Home Address: {homeAddress}</h4>
          <p>
            Your home address was unable to be verified. Are you sure that you
            would like to continue?
          </p>
        </Fragment>
      );
    } else if (!homeAddress && billingAddress) {
      return (
        <Fragment>
          <h4>Billing Address: {billingAddress}</h4>
          <p>
            Your billing address was unable to be verified. Are you sure that
            you would like to continue?
          </p>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <h4>Home: {homeAddress}</h4>
          <h4>Billing: {billingAddress}</h4>
          <p>
            Neither of these addresses were able to be verified. Are you sure
            that you would like to continue?
          </p>
        </Fragment>
      );
    }
  };
  return (
    <Modal show={show} centered>
      <Modal.Header
        placeholder=""
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <Modal.Title>Address could not be verified</Modal.Title>
      </Modal.Header>

      <Modal.Body>{getAddressMessage()}</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddressVerificationModal;
