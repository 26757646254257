import { Modal, Row, Spinner } from "react-bootstrap";
/* eslint-disable no-constant-condition */
import React, { Fragment, useEffect, useState } from "react";

import axiosInstance from "../../axios.instance";
import { FamilyMember } from "../../utility/member-portal-api-types";
import { Checkbox } from "../../utility/Checkbox";

const AddFamilyMemberHCSC = ({
  open,
  onClose,
  isPrimaryStudioOnly,
}: {
  open: boolean;
  onAdd: (data: { firstName: string; lastName: string; email: string }) => void;
  onClose: (selected: FamilyMember[]) => void;
  zipCode: string | undefined;
  isPrimaryStudioOnly: boolean;
}) => {
  const [open1] = useState<boolean>(open);
  const [loadingFamily, setLoadingFamily] = useState<boolean>(false);
  const [familyMembers, setFamilyMembers] = useState<FamilyMember[]>([]);
  const [filteredFamily, setFilteredFamily] = useState<FamilyMember[]>([]);

  const [checked, setChecked] = useState<boolean[]>(new Array(0));

  const handleClose = async () => {
    // if (isImpersonated) await setMaskDisabled();

    onClose(
      filteredFamily.filter((member, idx) => {
        return checked[idx];
      })
    );
  };

  useEffect(() => {
    setLoadingFamily(true);
    axiosInstance
      .request({
        url: `/account/family-members/`,
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        setFamilyMembers(response.data);
        setLoadingFamily(false);
      });
  }, []);

  useEffect(() => {
    const tempFilteredFamily = familyMembers.filter((member) => {
      return (
        member.status === "Eligible" || member.status === "UnderAgeDependent"
      );
    });
    // If primary is studio-only, adding underage dependents is not allowed
    const studioOnlyFilteredFamily = familyMembers.filter((member) => {
      return member.status === "Eligible";
    });
    setFilteredFamily(
      isPrimaryStudioOnly ? studioOnlyFilteredFamily : tempFilteredFamily
    );
    setChecked(new Array(tempFilteredFamily.length).fill(false));
  }, [familyMembers]);

  const handleCheck = (i) => {
    const updatedChecked = checked.map((item, index) =>
      index === i ? !item : item
    );
    setChecked(updatedChecked);
  };

  const handleSubmit = () => {
    handleClose();
  };

  const familyList = (
    <Fragment>
      {filteredFamily?.map((member, index) => (
        <Row key={index}>
          <Checkbox
            text={member.firstName + " " + member.lastName}
            htmlFor={member.firstName + " " + member.lastName + index}
            onChange={() => handleCheck(index)}
            checked={checked[index]}
            optional={true}
            bold={true}
            hyperLink={undefined}
            hyperLinkValue={undefined}
            name={undefined}
            classes={undefined}
          ></Checkbox>
          <div className="add-family__dob">
            DOB:{" "}
            {member.dateOfBirth.replaceAll("-", "/").substring(5) +
              "/" +
              member.dateOfBirth.replaceAll("-", "/").substring(0, 4)}
          </div>
          <hr className="add-family__hr"></hr>
        </Row>
      ))}
    </Fragment>
  );

  return (
    <Fragment>
      <Modal show={open1} onHide={handleClose} centered>
        <div className="add-family-member-form-container">
          <Modal.Title
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "1rem 1rem",
            }}
          >
            <h2>Add Family</h2>
            <div className="modal-add-family-close" onClick={handleClose}></div>
          </Modal.Title>
          <Modal.Header
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            className="modal-header-family-member"
          >
            Select the family member(s) (16 years or older*) you would like to
            add.
          </Modal.Header>
          <Modal.Body className="modal-body-family-member">
            {loadingFamily ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              familyList
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
              <button
                onClick={handleClose}
                className="col btn btn-outline-primary ml-2"
              >
                Cancel
              </button>
              <button
                disabled={!checked.includes(true)}
                onClick={handleSubmit}
                className="col mx-2 btn btn-secondary "
              >
                Add Member(s)
              </button>
              <div className="add-family__disclaimer">
                *Due to Tivity Health policy, you cannot enroll a family member
                under age 16 in the Well onTarget Fitness Program. In addition,
                family member(s) under age 18 enrolled in the Fitness Program
                should be accompanied by an adult (age 18 or older) when
                visiting participating fitness locations. Some locations may not
                allow members under age 18 to use the location regardless of
                membership.
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </Fragment>
  );
};

export default AddFamilyMemberHCSC;
