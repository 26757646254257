import { Button, Modal, Form, Row, Col, Spinner } from "react-bootstrap";
import React, { Fragment, useState, useCallback } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useHistory } from "react-router-dom";
import useSmartyStreets from "../../hooks/useSmartyStreets";
import { SmartyAddress } from "../wizard/types";
import axiosInstance, { axiosBaseInstance } from "../../axios.instance";
import { useConfig } from "../../configuration/useConfig";

const FwEligibilityModal = ({ onFail }: { onFail: () => void }) => {
  const [address, setAddress] = useState<SmartyAddress[]>([]);
  const [valid, setValid] = useState<boolean>(false);
  const [loading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { config } = useConfig();
  const history = useHistory();

  const states = [
    "",
    "AK",
    "AL",
    "AR",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MI",
    "MN",
    "MO",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VA",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY",
  ];
  const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const sessionData = sessionStorage.getItem("enrollment-flow");
  const sessionObject = JSON.parse(sessionData ?? "");
  const values = sessionObject.values;

  const [addrValues, setAddrValues] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: values.eligibility.zip,
    email: values.eligibility.email,
  });

  React.useEffect(() => {
    setAddress([
      {
        label: addrValues.address1,
        address: {
          city: addrValues.city,
          state: addrValues.state,
          zipcode: addrValues.zip,
          secondary: addrValues.address2 ?? "",
          streetLine: addrValues.address1,
          entries: 1,
        },
      },
    ]);
  }, [addrValues]);

  const onChangeSmarty = (address: SmartyAddress[]) => {
    if (address.length > 0) {
      const smartyAddress = address[0];
      setAddrValues({
        ...addrValues,
        ["address2"]: smartyAddress.address.secondary,
        ["state"]: smartyAddress.address.state,
        ["address1"]: smartyAddress.address.streetLine,
        ["city"]: smartyAddress.address.city,
        ["zip"]: smartyAddress.address.zipcode,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<unknown>) => {
    const { name, value } = e.target as HTMLInputElement;
    setAddrValues({ ...addrValues, [name]: value });
  };

  const handleInputChange = (input: string) => {
    setAddrValues({ ...addrValues, ["address1"]: input });
  };

  const { lookup, results } = useSmartyStreets();

  const handleSuggestions = useCallback(
    (query: string) => {
      if (addrValues.zip) {
        lookup(query, addrValues.zip);
      } else {
        lookup(query);
      }
    },
    [lookup]
  );

  const validEmail = expression.test(addrValues.email);

  React.useEffect(() => {
    if (
      addrValues.address1 &&
      addrValues.city &&
      addrValues.zip &&
      addrValues.state !== "" &&
      validEmail
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [addrValues]);

  type FWRegistration = {
    firstName: string | undefined;
    lastName: string | undefined;
    dateOfBirth: string | undefined;
    subscriberId: string | undefined;
    clientMemberId: string | undefined;
    groupNumber: string | undefined;
    sellingLedger: string | undefined;
    corporateEntityCode: string | undefined;
    address1: string | undefined;
    address2: string | undefined;
    city: string | undefined;
    state: string | undefined;
    zipCode: string | undefined;
    emailAddress: string | undefined;
    clientSpecificData: ClientSpecificData;
  };

  type ClientSpecificData = {
    clientCode: string | undefined;
    subProduct: string | undefined;
    divisionId: string | undefined;
    groupId: string | undefined;
    groupName: string | undefined;
    planName: string | undefined;
    fitnessOptIn: string | undefined;
    groupIndividualIndicator: string | undefined;
    lineOfBusiness: string | undefined;
    medicareType: string | undefined;
    misc3: string | undefined;
    misc6: string | undefined;
    misc7: string | undefined;
  };

  type FWRegistrationResponse = {
    wasSuccessful: boolean;
    personId: string;
    ALID: string;
    EligibilityOutcome?: string;
    cardAccessToken: string;
  };

  const onNotSuccess = () => {
    onFail();
  };

  const onFinish = () => {
    setIsLoading(true);
    const fwRegistration: FWRegistration = {
      firstName: values.eligibility.firstName,
      lastName: values.eligibility.lastName,
      dateOfBirth: `${values.eligibility.year}-${values.eligibility.month}-${values.eligibility.day}`,
      subscriberId: values.eligibility.subscriberId,
      clientMemberId: values.eligibility.clientMemberId,
      groupNumber: values.eligibility.groupNumber,
      sellingLedger: "Web",
      corporateEntityCode: values.eligibility.corpCode,
      address1: addrValues.address1,
      address2: addrValues.address2,
      city: addrValues.city,
      state: addrValues.state,
      zipCode: addrValues.zip,
      emailAddress: addrValues.email,
      clientSpecificData: {
        clientCode: values.eligibility.clientCode,
        groupId: values.eligibility.groupId,
        planName: values.eligibility.planName,
        subProduct: values.eligibility.subProduct,
        divisionId: values.eligibility.divisionId,
        groupName: values.eligibility.groupName,
        fitnessOptIn: values.eligibility.fitnessOptIn,
        groupIndividualIndicator: values.eligibility.groupIndividualIndicator,
        lineOfBusiness: values.eligibility.lineOfBusiness,
        medicareType: values.eligibility.medicareType,
        misc3: values.eligibility.misc3,
        misc6: values.eligibility.misc6,
        misc7: values.eligibility.misc7,
      },
    };

    axiosInstance
      .post("/fw-enrollment", JSON.stringify(fwRegistration))
      .then((response) => {
        const fwRegistrationResponse: FWRegistrationResponse = {
          wasSuccessful: response.data.wasSuccessful,
          personId: response.data.personId,
          ALID: response.data.aLID,
          EligibilityOutcome: response.data?.eligibilityOutcome,
          cardAccessToken: response.data.cardAccessToken,
        };

        if (!fwRegistrationResponse?.wasSuccessful) {
          onNotSuccess();
          setIsLoading(false);
        } else {
          axiosBaseInstance
            .get(
              `/clients/${config["client"]}/members/card/preview?personId=${fwRegistrationResponse.personId}&cardAccessToken=${fwRegistrationResponse.cardAccessToken}`,
              {}
            )
            .then((response) => {
              values.card = response.data;
              history.push("/success", response.data);
              sessionStorage.removeItem("pipeline-session-id");
              setIsLoading(false);
            })
            .catch((e) => console.log(e)); // WRITE ERROR CODE
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
        onFail();
      });
  };

  const loadButtonSpinner = () => {
    return (
      <Spinner
        style={{ borderWidth: ".1em", width: 15, height: 15 }}
        animation="border"
        as="span"
        size="sm"
      />
    );
  };

  return (
    <Fragment>
      <Modal show={true}>
        <Modal.Header
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="modal-header-form"
        >
          <div className="modal-header-form__checkmark"></div>
          <h2>
            <b>Eligibility Confirmed!</b>
          </h2>
        </Modal.Header>
        <Modal.Body
          className="modal-body-fw-form"
          style={{ textAlign: "left" }}
        >
          <div className="modal-body-fw-text">
            <b>Welcome Fitness Works Member!</b>
            <br />
            Please complete the fields below to finish registration.
            <br />
            <br />
          </div>
          <div>
            <div className="registration-form__fields-container">
              <Row>
                <Form.Group as={Col} sm={12} className="mb-3 p-0">
                  <Form.Label
                    className="eligibility-form__label edit-profile__title"
                    column
                    sm={12}
                  >
                    ADDRESS<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <AsyncTypeahead
                    id="address"
                    isLoading={loading}
                    selected={address}
                    onSearch={handleSuggestions}
                    onChange={onChangeSmarty}
                    onInputChange={handleInputChange}
                    renderMenuItemChildren={(option) => {
                      return `${option.address.streetLine} ${option.address.city}, ${option.address.state} ${option.address.zipcode}`;
                    }}
                    options={results.map((r) => ({
                      label: `${r.streetLine}`,
                      address: r,
                    }))}
                    className="edit-profile__input2"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} sm={12} className="mb-3 p-0">
                  <Form.Label className="eligibility-form__label edit-profile__title">
                    APT, UNIT, ETC (OPTIONAL)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name={"address2"}
                    value={addrValues.address2}
                    onChange={handleChange}
                    className="edit-profile__input2"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} xs={6} md={5} className="mb-3 p-0">
                  <Form.Label className="eligibility-form__label edit-profile__title">
                    CITY<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name={"city"}
                    value={addrValues.city}
                    onChange={handleChange}
                    className="edit-profile__input2"
                  />
                </Form.Group>
                <Form.Group as={Col} xs={3} className="mb-3 p-0 mx-3">
                  <Form.Label className="eligibility-form__label edit-profile__title">
                    STATE<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className="form-control edit-profile__input2 form-select"
                    name={"state"}
                    value={addrValues.state.toUpperCase()}
                    onChange={handleChange}
                  >
                    {states.map((state, index) => {
                      return (
                        <option key={`address-${index}`} value={state}>
                          {state}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs={5} lg={3} className="mb-3 p-0">
                  <Form.Label className="eligibility-form__label edit-profile__title">
                    ZIP CODE<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name={"zip"}
                    value={addrValues.zip}
                    onChange={handleChange}
                    className="edit-profile__input2"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} sm={12} className="mb-3 p-0">
                  <Form.Label className="eligibility-form__label edit-profile__title">
                    EMAIL<span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name={"email"}
                    value={addrValues.email}
                    onChange={handleChange}
                    className="edit-profile__input2"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Button
                  bsPrefix="btn font-weight-bold eligibility-form__finish_registration_button"
                  style={{ whiteSpace: "nowrap", textAlign: "center" }}
                  disabled={!valid || isLoading}
                  onClick={onFinish}
                >
                  {isLoading
                    ? loadButtonSpinner()
                    : " Finish Registration & Continue"}
                </Button>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default FwEligibilityModal;
