import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
/* eslint-disable no-constant-condition */
import React, { useEffect, useState } from "react";

import FamilyStatusModal from "../../components/wizard/components/FamilyStatusModal";
import InfoIcon from "../../resources/information-help-svgrepo-com.svg";
import axiosInstance from "../../axios.instance";
import { validDay, validMonth, validYear } from "../../utility/Regex.jsx";

const AddFamilyMember = ({
  open,
  onAdd,
  onClose,
  zipCode,
}: {
  open: boolean;
  onAdd: (data: { firstName: string; lastName: string; email: string }) => void;
  onClose: () => void;
  zipCode: string | undefined;
}) => {
  type AddDependent = {
    person: {
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      email: string;
      zip: string;
      relationshipCode: string;
    };
  };

  const initialData = {
    firstName: {
      value: "",
      isValid: false,
      isFocus: false,
      isBlur: false,
    },
    lastName: {
      value: "",
      isValid: false,
      isFocus: false,
      isBlur: false,
    },
    month: { value: "", isValid: false, isFocus: false, isBlur: false },
    day: { value: "", isValid: false, isFocus: false, isBlur: false },
    year: { value: "", isValid: false, isFocus: false, isBlur: false },
    email: {
      value: "",
      isValid: false,
      isFocus: false,
      isBlur: false,
    },
    relationship: {
      value: "",
      isValid: false,
      isFocus: false,
      isBlur: false,
    },
  };

  const errorMessageMappings = {
    "Email is already in use":
      "The email address provided is linked to an existing account. Please try a different email address or visit the help page for more assistance.",
    "Dependent already has an active membership":
      "Our records indicate this member already has an active account with the program and cannot be added to your account. Please review the information provided for accuracy or visit the help page for more assistance.",
    "Account already includes a spouse":
      "Our records indicate you already have an active spouse on your account.",
  };
  const genericErrormessage =
    "We encountered an issue when adding this family member to your account. Please review the information provided for accuracy or visit the help page for more assistance.";

  const [data, setData] = useState(initialData);
  const [status, setStatus] = useState("");
  const [message] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isAgeVerify, setIsAgeVerify] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [openHangTight, setOpenHangTight] = useState<boolean>(true);
  const [open1, setOpen1] = useState<boolean>(open);
  const [progress, setProgress] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [isFailed, setIsFailed] = useState<boolean>(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  function isValidName(name) {
    return /^([A-Za-zñÑàáäÀÁÂèéêÈÉÊìíîÌÍÎòóôÒÓÔùúûÙÚÛ]|-|\.|'|\s)+$/.test(name);
  }

  const handleClose = async () => {
    // if (isImpersonated) await setMaskDisabled();
    onClose();
  };

  const payload = {
    firstName: data.firstName.value,
    lastName: data.lastName.value,
    dateOfBirth: `${data.year.value}-${data.month.value}-${data.day.value}`,
    email: data.email.value,
    relationshipCode: data.relationship.value,
  };

  const handleCallback = () => {
    onAdd(payload);
  };

  useEffect(() => {
    if (
      data.firstName.isValid &&
      data.lastName.isValid &&
      data.email.isValid &&
      validMonth.test(data.month?.value) &&
      validDay.test(data.day?.value) &&
      validYear.test(data.year?.value) &&
      data.relationship.isValid
    ) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  }, [data]);

  useEffect(() => {
    if (openHangTight && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, openHangTight]);

  useEffect(() => {
    if (open1 && status === "CONFIRMED") {
      clearData(initialData);
    }
  }, [open1]);

  const handleChangeData = (e) => {
    if (e.target.name === "firstName" || e.target.name === "lastName") {
      if (!isValidName(e.target.value)) {
        setData({
          ...data,
          [e.target.name]: {
            ...data[e.target.name],
            value: e.target.value,
            isValid: false,
          },
        });
      } else {
        setData({
          ...data,
          [e.target.name]: {
            ...data[e.target.name],
            value: e.target.value,
            isValid: true,
          },
        });
      }
    } else if (e.target.name === "email") {
      if (!isValidEmail(e.target.value)) {
        setData({
          ...data,
          [e.target.name]: {
            ...data[e.target.name],
            value: e.target.value,
            isValid: false,
          },
        });
      } else {
        setData({
          ...data,
          [e.target.name]: {
            ...data[e.target.name],
            value: e.target.value,
            isValid: true,
          },
        });
      }
    } else {
      setData({
        ...data,
        [e.target.name]: {
          ...data[e.target.name],
          value: e.target.value,
          isValid: true,
        },
      });
    }
  };

  const handleBlur = (e) => {
    setData({
      ...data,
      [e.target.name]: {
        ...data[e.target.name],
        isBlur: true,
      },
    });
  };

  const dateOfBirth = new Date(
    new Date(
      parseInt(data.year.value, 10),
      parseInt(data.month.value, 10) - 1,
      parseInt(data.day.value),
      10
    ).setHours(0, 0, 0, 0)
  ).getTime();

  const eightTeenYearsAgo = () => {
    const date = new Date(new Date().setHours(0, 0, 0, 0));

    date.setFullYear(date.getFullYear() - 18);

    return date.getTime();
  };

  const twentySixYearsAgo = () => {
    const date = new Date(new Date().setHours(0, 0, 0, 0));

    date.setFullYear(date.getFullYear() - 26);
    return date.getTime();
  };

  const verifyAge = (relationshipCode) => {
    if (relationshipCode === "D") {
      if (
        dateOfBirth <= eightTeenYearsAgo() &&
        dateOfBirth > twentySixYearsAgo()
      ) {
        return true;
      }
      return false;
    } else {
      if (dateOfBirth <= eightTeenYearsAgo()) {
        return true;
      }
      return false;
    }
  };

  const validateMonth = () => {
    if (data.month?.value === "") {
      return false;
    } else if (data.month?.value) {
      return !validMonth.test(data.month?.value);
    } else {
      return false;
    }
  };

  const validateDay = () => {
    if (data.day?.value === "") {
      return false;
    } else if (data.day?.value) {
      return !validDay.test(data.day?.value);
    } else {
      return false;
    }
  };

  const validateYear = () => {
    if (data.year?.value === "") {
      return false;
    } else if (data.year?.value) {
      return !validYear.test(data.year?.value);
    } else {
      return false;
    }
  };
  const clearData = (init) => setData(init);

  const handleSubmit = () => {
    // Request body
    const body: AddDependent = {
      person: {
        firstName: data.firstName.value,
        lastName: data.lastName.value,
        dateOfBirth: `${data.year.value}-${data.month.value}-${data.day.value}`,
        email: data.email.value,
        zip: zipCode ? zipCode : "",
        relationshipCode: data.relationship.value,
      },
    };
    // Age validation
    if (verifyAge(data.relationship.value)) {
      setStatus("LOADING");
      setOpenHangTight(true);
      setProgress(0);
      setOpen1(false);
      setIntervalId(setInterval(() => setProgress((p) => p + 25), 300));
      setIsAgeVerify(false);
      axiosInstance
        .post("/account/dependents", body, {
          headers: {
            accept: "application/json",
          },
        })
        .then((response) => {
          // console.log(response.data);
          if (response.data?.success) {
            handleCallback();
            setStatus("CONFIRMED");
            setIsFailed(false);
          } else {
            setErrorMessage(
              errorMessageMappings[response.data?.error[0].message] ||
                genericErrormessage
            );
            setOpenHangTight(false);
            setOpen1(true);
            setIsFailed(true);
          }
        })
        .catch((error) => {
          console.log(error.toJSON());
          setStatus("error");
        });
    } else {
      setIsAgeVerify(true);
    }
  };

  return open1 ? (
    <Modal show={open1} onHide={handleClose} centered>
      <div className="add-family-member-form-container">
        <Modal.Title
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "1rem 1rem",
          }}
        >
          <h2>Add Family Member</h2>
          <div className="modal-add-family-close" onClick={handleClose}></div>
        </Modal.Title>
        <Modal.Header
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="modal-header-family-member"
        >
          Family members must be 18 years or older; all children must be between
          ages 18 to 25
        </Modal.Header>
        {isFailed && (
          <Alert variant="danger" className="alert-info">
            <div className="alert-info__flex">
              <img
                alt="Information Icon"
                className="alert-info__icon"
                src={InfoIcon}
              />
              {errorMessage}
            </div>
          </Alert>
        )}
        <Modal.Body className="modal-body-family-member">
          <Row className="add-family-member-form__row">
            <Form.Group as={Col} controlId="firstName" xs={6} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 add-family-member-form__label">
                First Name <span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                maxLength={30}
                name="firstName"
                value={data.firstName?.value}
                isInvalid={!data.firstName.isValid && data.firstName.isBlur}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleChangeData(e);
                }}
              />
              <Form.Control.Feedback type="invalid">
                <Form.Label>
                  Name can only contain letters, hyphens, apostrophes or periods
                </Form.Label>
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="lastName" xs={6}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 add-family-member-form__label">
                Last Name <span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                required
                maxLength={30}
                name="lastName"
                value={data.lastName?.value}
                isInvalid={!data.lastName.isValid && data.lastName.isBlur}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleChangeData(e);
                }}
              />
              <Form.Control.Feedback type="invalid">
                <Form.Label>
                  Name can only contain letters, hyphens, apostrophes or periods
                </Form.Label>
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Label className="form-control-sm col-form-label p-0 add-family-member-form__label">
            Date of Birth <span className="required-field-marker">*</span>
          </Form.Label>
          <Row className="add-family-member-form__row">
            <Form.Group as={Col} controlId="month" xs={3} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0">
                Month
              </Form.Label>
              <Form.Control
                type="text"
                name="month"
                maxLength={2}
                isInvalid={
                  (validateMonth() && data.month.isBlur) ||
                  (!validMonth.test(data.month?.value) && data.month.isBlur)
                }
                value={data.month?.value}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleChangeData(e);
                }}
                placeholder="MM"
              >
                {/* {getMonths()} */}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="day" xs={3}>
              <Form.Label className="form-control-sm col-form-label p-0">
                Day
              </Form.Label>
              <Form.Control
                type="text"
                name="day"
                maxLength={2}
                isInvalid={
                  (validateDay() && data.day.isBlur) ||
                  (!validDay.test(data.day?.value) && data.day.isBlur)
                }
                value={data.day?.value}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleChangeData(e);
                }}
                placeholder="DD"
              >
                {/* {getDays()} */}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="year" xs={3}>
              <Form.Label className="form-control-sm col-form-label p-0 pb-0">
                Year
              </Form.Label>
              <Form.Control
                type="text"
                name="year"
                maxLength={4}
                isInvalid={
                  (validateYear() && data.year.isBlur) ||
                  (!validYear.test(data.year?.value) && data.year.isBlur)
                }
                value={data.year?.value}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleChangeData(e);
                }}
                placeholder="YYYY"
              >
                {/* {getYears()} */}
              </Form.Control>
            </Form.Group>
          </Row>
          {isAgeVerify && (
            <>
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 add-family-member-form__label__error">
                The member you input does not meet the age requirements, please
                confirm the date of birth or remove the member.
              </Form.Label>
            </>
          )}
          <Row className="add-family-member-form__row">
            <Form.Group as={Col} xs={6} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 add-family-member-form__label">
                Email Address <span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={data.email.value}
                isInvalid={!data.email.isValid && data.email.isBlur}
                onBlur={(e) => {
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleChangeData(e);
                }}
              />
              <Form.Control.Feedback type="invalid">
                <Form.Label>Please enter a valid email address</Form.Label>
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="add-family-member-form__row">
            <Form.Group as={Col} xs={12} className="mb-3">
              <Form.Label className="form-control-sm col-form-label p-0 pb-1 add-family-member-form__label">
                Dependent's relationship to you{" "}
                <span className="required-field-marker">*</span>
              </Form.Label>
              <Form.Group
                as={Col}
                xs={12}
                className="mb-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Form.Check
                  inline
                  type="radio"
                  name="relationship"
                  label="Spouse"
                  value="S"
                  // isInvalid={!data.relationship.isValid}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  onChange={(e) => {
                    handleChangeData(e);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "0",
                    marginLeft: "-.5rem",
                  }}
                />
                <Form.Check
                  inline
                  type="radio"
                  name="relationship"
                  label="Dependent Child"
                  value="D"
                  // isInvalid={!data.relationship.isValid}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  onChange={(e) => {
                    handleChangeData(e);
                  }}
                  style={{ display: "flex", alignItems: "center" }}
                />
              </Form.Group>
            </Form.Group>
          </Row>
        </Modal.Body>
        <hr style={{ marginTop: "-1rem" }}></hr>
        <Modal.Footer>
          <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
            <button
              onClick={handleClose}
              className="col btn btn-outline-primary ml-2"
            >
              Cancel
            </button>
            <button
              disabled={!isCompleted}
              onClick={handleSubmit}
              className="col mx-2 btn btn-secondary "
            >
              Confirm Addition
            </button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  ) : openHangTight ? (
    <Modal
      show={openHangTight}
      onHide={handleClose}
      centered
      className="modal-family"
    >
      <FamilyStatusModal
        show={openHangTight}
        status={status}
        message={message}
        progress={progress}
        firstName={data.firstName.value}
        lastName={data.lastName.value}
        onDone={handleClose}
        onAdd={() => setOpen1(true)}
      />
    </Modal>
  ) : null;
};

export default AddFamilyMember;
