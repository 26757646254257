import { Button, Col, Nav, Row } from "react-bootstrap";
import {
  IconDefinition,
  faCog,
  faFileInvoiceDollar,
  faHome,
  faQuestionCircle,
  faRunning,
} from "@fortawesome/free-solid-svg-icons";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Fragment } from "react";
import {
  IUser,
  USER_PAYROLL_STATUS,
  USER_STATUS,
  UserContext,
} from "../context/UserProvider";

import { AccountContext } from "../context/AccountProvider";

import ActivityLog from "../components/account/Activity/ActivityLog";
import BannerWrapper from "../components/shared/BannerWrapper";
import Billing from "../pages/account/Billing";
import TermedNotEligibleBanner from "../components/account/TermedNotEligibleBanner";
import { ErrorBoundary } from "react-error-boundary";
import FAQ from "../pages/FAQ";
import Help from "../pages/account/Help";
import InformationDisplay from "../components/account/InformationDisplay";
import LegalDisclaimer from "../components/shared/LegalDisclaimer";
import PrimeOnDemand from "../pages/PrimeOnDemand";
import Migration from "../pages/Migration";
import NavBar from "../components/navigation/NavBar";
import NotFound from "../pages/NotFound";
import OutStandingBalanceBanner from "../components/account/OutStandingBalanceBanner";
import Overview from "../pages/account/Overview";
import PoweredBy from "../components/shared/PoweredBy";
import SideNavigation from "../components/account/SideNavigation";
import SomethingWentWrong from "../pages/SomethingWentWrong";
import ViewProfile from "../pages/account/ViewProfile";
import Choices from "../pages/account/Choices";
import ChoicesDetails from "../pages/account/ChoicesDetails";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import useScrollToTop from "../hooks/useScrollToTop";
import { useState } from "react";
import DiscardCancelBanner from "../components/account/DiscardCancelBanner";
import DependentTermedBanner from "../components/account/DependentTermedBanner";
import WholeHealthLiving from "../pages/account/WholeHealthLiving";
import Locations from "../pages/Locations";
import UpcomingPaymentDisplay from "../components/account/UpcomingPaymentDisplay";
import {
  AccountEligibility,
  AccountSummaryMember,
} from "../utility/member-portal-api-types";
import { useConfig } from "../configuration/useConfig";
import ManagePlanUpdate from "../pages/account/ManagePlanUpdate";
import UpgradeBanner from "../components/account/UpgradeBanner";
import PendingDowngradeBanner from "../components/account/PendingDowngradeBanner";
import moment from "moment";
import WaivedEnrollmentBanner from "../components/account/WaivedEnrollmentBanner";
import { momentStartOfDay } from "../utility/util";

type NavLink = {
  label: string | JSX.Element;
  url: string;
  cssClass?: string;
  newTab?: boolean;
  cssId?: string;
  onClick?: () => void;
};

export type SideNavLink = {
  label: string;
  url?: string;
  icon?: IconDefinition;
  newTab?: boolean;
  disabledForStatus?: string[];
  onClick?: () => Promise<void>;
};

const AccountRouting = () => {
  toast.configure();
  useScrollToTop();

  const [fullWidth, setFullWidth] = useState(false);

  const { nextPayment, member, clientEligibilityStatus, clientPayrollStatus } =
    useContext(AccountContext);

  const { user } = useContext(UserContext);
  const { path } = useRouteMatch();
  const location = useLocation();
  const { logout } = useAuth0();
  const { clientConfig, config } = useConfig();
  const termReasonPattern = /^RS5[0-9]/;
  const { isAuthenticated } = useAuth0();

  if (!user.info) throw new Error("No UserInfo");
  const userInfo = user.info;

  const history = useHistory();

  const handleLogout = async () => {
    logout();
  };

  const [, setIsTermedStatus] = useState(false);
  const [showUpcomingPaymentDisplay, setShowUpcomingPaymentDisplay] =
    useState(false);
  const fullwidthRoutes = ["primeOnDemand", "migrate", "locations"];
  // discard cancellation banner
  const [showDiscardCancellation, setShowDiscardCancellation] = useState(false);
  const [isFullyTermed, setIsFullyTermed] = useState(false);
  const [isFitnessTermed, setIsFitnessTermed] = useState(false);
  const [is30daysPastTerm, setIs30daysPastTerm] = useState(false);
  const [primeEligibility, setPrimeEligibility] = useState<
    AccountEligibility[]
  >([]);
  const [hasActiveNonPrimeEligibility, setHasActiveNonPrimeEligibility] =
    useState(false);

  const topNavLinks: NavLink[] = [
    {
      label: "My Account",
      url: "/account",
      cssClass: "nav-link nav-link-bold",
    },
    { label: "Locations", url: "/account/locations", cssClass: "nav-link" },
    { label: "FAQs", url: "/account/faqs", cssClass: "nav-link" },
    {
      label: "View Profile",
      url: "/account/view-profile",
      cssClass: "nav-link isHiddenOnLarge",
    },
    {
      label: "Account Overview",
      url: "/account",
      cssClass: "nav-link isHiddenOnLarge",
    },
    {
      label: "Manage Plan",
      url: "/account/manage",
      cssClass: "nav-link isHiddenOnLarge",
    },
    {
      label: "Billing & Payment",
      url: "/account/billing",
      cssClass: "nav-link isHiddenOnLarge",
    },
    {
      label: "Activity Log",
      url: "/account/activity",
      cssClass: "nav-link isHiddenOnLarge",
    },
    {
      label: "Terms of Use",
      url: "/terms",
      newTab: true,
      cssClass: "nav-link isHiddenOnLarge",
    },
    {
      label: "Privacy Policy",
      url: "/privacy-policy",
      newTab: true,
      cssClass: "nav-link isHiddenOnLarge",
    },
    {
      label: "Fitness Program Agreement",
      url:
        config["client"] === "bsca" || config["client"] === "bcbsa"
          ? `/fitness-program-agreement?clientCode=${config["client"]}`
          : "/fitness-program-agreement",
      newTab: true,
      cssClass: "nav-link isHiddenOnLarge",
    },
    {
      label: "Help",
      url: "/account/help",
      cssClass: "nav-link isHiddenOnLarge",
    },
    {
      label: "Log Out",
      url: "/",
      onClick: handleLogout,
      cssClass: "isHiddenOnLarge logout-button",
    },
  ];

  const sideNavLinks: SideNavLink[] = [
    { label: "Account Home", url: "/account", icon: faHome },
    {
      label: "Manage Plan",
      url: "/account/manage",
      icon: faCog,
      disabledForStatus: ["TERMED", "CANCELLED", "NONE"],
    },
    {
      label: "Billing & Payment",
      url: "/account/billing",
      icon: faFileInvoiceDollar,
      disabledForStatus: ["NONE"],
    },
    {
      label: "Activity Log",
      url: "/account/activity",
      icon: faRunning,
      disabledForStatus: ["NONE"],
    },
    { label: "Help", url: "/account/help", icon: faQuestionCircle },
    { label: "Terms of Use", url: "/terms", newTab: true },
    { label: "Privacy Policy", url: "/privacy-policy", newTab: true },
    {
      label: "Program Agreement",
      url:
        config["client"] === "bsca" || config["client"] === "bcbsa"
          ? `/fitness-program-agreement?clientCode=${config["client"]}`
          : "/fitness-program-agreement",
      newTab: true,
    },
    {
      label: "Log Out",
      onClick: handleLogout,
    },
  ];

  const [displayedTopNavLinks, setDisplayedTopNavLinks] = useState(topNavLinks);
  const [displayedSideNavLinks, setDisplayedSideNavLinks] =
    useState(sideNavLinks);
  const filterNavLinks = (
    member: AccountSummaryMember | undefined,
    user: IUser
  ): void => {
    const isDependent = !member?.eligibilities[0]?.isPrimary;
    const isDAL = clientConfig.isDependentAccessLinked;

    // Manage Plan
    let showManagePlan = true;
    if (user.isFWEnrolled || isDependent || user.hasMutuallyWellFreeAccount)
      showManagePlan = false;

    // Billing & Payment
    let showBilling = true;
    if (user.isFWEnrolled) showBilling = false;
    if (isDAL && isDependent) showBilling = false;
    if (user.hasMutuallyWellFreeAccount) showBilling = false;

    // Help
    let showHelp = true;
    if (config["client"] === "walmart" && isDependent) showHelp = false;

    // Privacy Policy
    let showPrivacyPolicy = true;
    if (config["client"] === "walmart" && isDependent)
      showPrivacyPolicy = false;

    // Program Agreement
    let showProgramAgreement = true;
    if (
      config["client"] === "walmart" &&
      clientPayrollStatus === USER_PAYROLL_STATUS.Payroll
    )
      showProgramAgreement = false;

    // Locations Program Agreement
    let showLocations = false;
    if (isAuthenticated) showLocations = true;

    setDisplayedTopNavLinks(
      topNavLinks.filter((link) => {
        switch (link.label) {
          case "Manage Plan":
            return showManagePlan;
          case "Billing & Payment":
            return showBilling;
          case "Help":
            return showHelp;
          case "Privacy Policy":
            return showPrivacyPolicy;
          case "Program Agreement":
            return showProgramAgreement;
          case "Locations":
            return showLocations;
          default:
            return true;
        }
      })
    );
    setDisplayedSideNavLinks(
      sideNavLinks.filter((link) => {
        switch (link.label) {
          case "Manage Plan":
            return showManagePlan;
          case "Billing & Payment":
            return showBilling;
          case "Help":
            return showHelp;
          case "Privacy Policy":
            return showPrivacyPolicy;
          case "Program Agreement":
            return showProgramAgreement;
          default:
            return true;
        }
      })
    );
  };

  useEffect(() => {
    filterNavLinks(member, user);
  }, [user.isFWEnrolled, member, clientPayrollStatus]);

  //specific routes using full width layouts
  useEffect(() => {
    setFullWidth(
      fullwidthRoutes.filter((r) => location.pathname.toLowerCase().includes(r))
        .length > 0
    );
  }, [location]);

  //migration flow
  useEffect(() => {
    const hasMigrated = sessionStorage.getItem("has_migrated");
    if (!hasMigrated) {
      history.push("/account/migrate");
    }
  }, [userInfo]);

  useEffect(() => {
    if (
      member?.eligibilities[0]?.currentSubscriptionStatus === "Cancelled" &&
      user.status != USER_STATUS.ELIGIBLE
    ) {
      setIsTermedStatus(true);
    } else {
      setIsTermedStatus(false);
    }
  }, [member?.eligibilities[0]?.currentSubscriptionStatus, user.status]);

  useEffect(() => {
    const isDependent = !member?.eligibilities[0]?.isPrimary;
    setShowUpcomingPaymentDisplay(
      !isDependent && !(member?.eligibilities[0]?.productCode === "261")
    );
  }, [member]);

  useEffect(() => {
    const primeEligibility = member?.eligibilities?.filter((eligibility) => {
      return eligibility.productCode === "226";
    });

    if (primeEligibility && primeEligibility.length !== 0) {
      setPrimeEligibility(primeEligibility);
      const memberTermDate = primeEligibility[0].memberTermDate;
      const currentDate = momentStartOfDay().toDate();
      const compareDates =
        moment(memberTermDate).add(29, "days").toDate() < currentDate;
      setIs30daysPastTerm(compareDates);
    } else {
      setPrimeEligibility([]);
    }

    const filteredEligibilities = member?.eligibilities?.filter(
      (eligibility) => {
        return eligibility.isMembershipActive;
      }
    );

    if (
      filteredEligibilities &&
      filteredEligibilities.length == 1 &&
      (filteredEligibilities[0].productCode === "260" ||
        filteredEligibilities[0].productCode === "261") &&
      filteredEligibilities[0].isMembershipActive
    ) {
      setHasActiveNonPrimeEligibility(true);
    } else {
      setHasActiveNonPrimeEligibility(false);
    }
  }, [member]);

  useEffect(() => {
    if (primeEligibility[0] && !primeEligibility[0].isMembershipActive) {
      if (!hasActiveNonPrimeEligibility) {
        setIsFullyTermed(true);
        setIsFitnessTermed(false);
      } else {
        setIsFullyTermed(false);
        setIsFitnessTermed(true);
      }
    }
  }, [primeEligibility, hasActiveNonPrimeEligibility]);

  useEffect(() => {
    let currentEligibility = member?.eligibilities[0];
    if (config["client"] === "hcsc") {
      const productEligible = member?.eligibilities?.find(
        (e) => e.productCode === "226"
      );
      if (productEligible) {
        currentEligibility = productEligible;
      }
    }
    const termReason = currentEligibility?.termReason ?? "";

    if (
      currentEligibility?.isPendingTermination &&
      !termReasonPattern.test(termReason)
    ) {
      setShowDiscardCancellation(true);
    } else {
      setShowDiscardCancellation(false);
    }
  }, [member]);

  const getName = useCallback(() => {
    if (userInfo && userInfo?.givenName && userInfo?.familyName) {
      return `${userInfo.givenName} ${userInfo.familyName}`;
    }
    return "";
  }, [userInfo]);

  const getInitials = useCallback(() => {
    if (user.AccountSummaryMember) {
      return `${user.AccountSummaryMember.firstName.charAt(
        0
      )} ${user.AccountSummaryMember.lastName.charAt(0)}`;
    } else {
      return `${userInfo["email"].charAt(0)}`;
    }
  }, [userInfo]);

  return (
    <Fragment>
      <Route
        exact
        path={[
          `${path}/`,
          `${path}/activity`,
          `${path}/faqs`,
          `${path}/locations`,
          `${path}/help`,
          `${path}/manage`,
          `${path}/manage/*`,
          `${path}/billing`,
          `${path}/view-profile`,
          `${path}/choices`,
          `${path}/choices/*`,
        ]}
      >
        <NavBar logo id={true}>
          <Nav activeKey={path}>
            <Nav.Link
              className="my-account-header"
              id="account-header"
            ></Nav.Link>
            {displayedTopNavLinks.map((linkItem: NavLink, index: number) => (
              <Fragment key={index}>
                {!linkItem.onClick ? (
                  <Nav.Link
                    as={Link}
                    eventKey={index}
                    to={linkItem.url}
                    key={index}
                    target={linkItem.newTab ? "_blank" : ""}
                    rel="noreferrer"
                    id={linkItem.cssId ? linkItem.cssId : ""}
                    className={linkItem.cssClass ? linkItem.cssClass : ""}
                  >
                    {linkItem.label}
                  </Nav.Link>
                ) : (
                  <Button
                    variant="link"
                    onClick={linkItem.onClick}
                    className={linkItem.cssClass ? linkItem.cssClass : ""}
                  >
                    {linkItem.label}
                  </Button>
                )}
              </Fragment>
            ))}
          </Nav>
        </NavBar>
      </Route>

      <div className="account-template h-100">
        <Route
          exact
          path={[
            `${path}/`,
            `${path}/activity`,
            `${path}/help`,
            `${path}/manage`,
            `${path}/manage/*`,
            `${path}/billing`,
            `${path}/view-profile`,
            `${path}/choices`,
            `${path}/choices/*`,
          ]}
        >
          {!location.pathname.startsWith(`${path}/choices`) && (
            <SideNavigation
              className="account-template__nav d-none d-lg-flex"
              name={getName()}
              avatar={getInitials()}
              links={displayedSideNavLinks}
              status={member?.eligibilities[0]?.currentSubscriptionStatus || ""}
            />
          )}
        </Route>
        <div
          className={`${
            !fullWidth
              ? "account-template__content"
              : "account-template__content-no-padding"
          } h-100`}
          style={{
            backgroundColor: location.pathname.startsWith(`${path}/choices`)
              ? "#FFFFFF"
              : "",
          }}
        >
          <span className="p-2"></span>
          <Row className="h-100">
            <Col xs={12} xl={fullWidth ? 12 : 9} className="h-100">
              <Switch>
                <Route exact path={`${path}/`}>
                  <InformationDisplay
                    header="Account Home"
                    canShowReEnrollBanner={
                      isFullyTermed &&
                      config["client"] !== "mutual-of-omaha" &&
                      config["client"] !== "hcsc" &&
                      (is30daysPastTerm ||
                        (config["client"] === "walmart" &&
                          member?.eligibilities[0].isPrimary === true))
                    }
                  />
                  {clientConfig.reenrollmentRule === "30 Days" &&
                    (isFullyTermed || isFitnessTermed) &&
                    !is30daysPastTerm &&
                    (config["client"] === "hcsc" ||
                      config["client"] === "bcbsa" ||
                      config["client"] === "bsca") && (
                      <WaivedEnrollmentBanner />
                    )}
                  {showUpcomingPaymentDisplay && (
                    <UpcomingPaymentDisplay></UpcomingPaymentDisplay>
                  )}
                  <BannerWrapper show={nextPayment?.overdue ?? false}>
                    <OutStandingBalanceBanner
                      amount={nextPayment?.amount ?? 0}
                      due={nextPayment?.due ?? ""}
                    />
                  </BannerWrapper>
                  {showDiscardCancellation && <DiscardCancelBanner />}
                  {member?.eligibilities[0]?.futureSubscription && (
                    <PendingDowngradeBanner />
                  )}

                  {!member?.eligibilities[0]?.isPrimary &&
                    member?.eligibilities[0]?.currentSubscriptionStatus ===
                      "Cancelled" &&
                    config["client"] === "walmart" && <DependentTermedBanner />}
                  <BannerWrapper
                    show={
                      !!member?.eligibilities[0]?.isPrimary &&
                      !clientEligibilityStatus &&
                      clientPayrollStatus === USER_PAYROLL_STATUS.Payroll
                    }
                  >
                    <TermedNotEligibleBanner />
                  </BannerWrapper>
                  {((isFitnessTermed && is30daysPastTerm) ||
                    (isFullyTermed &&
                      is30daysPastTerm &&
                      config["client"] === "hcsc") ||
                    (isFullyTermed && config["client"] === "mutual-of-omaha") ||
                    (primeEligibility.length === 0 &&
                      hasActiveNonPrimeEligibility) ||
                    user.hasMutuallyWellFreeAccount) && <UpgradeBanner />}
                  <Overview />
                </Route>
                <Route exact path={`${path}/migrate`}>
                  <Migration />
                </Route>
                <Route exact path={`${path}/activity`}>
                  <InformationDisplay header="Activity Log" />
                  <div className="activity-log-container">
                    <ActivityLog />
                  </div>
                </Route>
                <Route exact path={`${path}/billing`}>
                  <InformationDisplay header="Billing and Payment" />
                  <Billing />
                </Route>
                <Route path={`${path}/primeOnDemand`}>
                  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                    <PrimeOnDemand />
                  </ErrorBoundary>
                </Route>
                <Route exact path={`${path}/manage`}>
                  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
                    <ManagePlanUpdate />
                  </ErrorBoundary>
                </Route>
                <Route exact path={`${path}/faqs`}>
                  <FAQ />
                </Route>
                <Route exact path={`${path}/locations`}>
                  <Locations />
                </Route>
                <Route exact path={`${path}/help`}>
                  <InformationDisplay header="Help" />
                  <Help />
                </Route>
                <Route exact path={`${path}/view-profile`}>
                  <ViewProfile />
                </Route>
                <Route exact path={`${path}/choices`}>
                  <Choices />
                </Route>
                <Route exact path={`${path}/choices/:id`}>
                  <ChoicesDetails />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
              {!location.pathname.startsWith(`${path}/choices`) &&
                user.hasWHLChoices && <WholeHealthLiving />}
            </Col>
          </Row>
        </div>
        {!location.pathname.startsWith(`${path}/choices`) && (
          <div className="account-template__footer">
            <LegalDisclaimer />
            <PoweredBy />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default AccountRouting;
