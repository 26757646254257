import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/esm/Modal";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useConfig } from "../../../configuration/useConfig";

const AgreeTermsModal = ({
  open,
  onAgree,
  hideProgram,
}: {
  open: boolean;
  onAgree: () => void;
  hideProgram?: boolean;
}) => {
  const { config } = useConfig();
  const [handlePrivacy, setHandlePrivacy] = useState(false);
  const [handleFitness, setHandleFitness] = useState(false);
  return (
    <Modal show={open} centered className="agree-terms-modal">
      <Modal.Header
        placeholder=""
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="modal-header"
      >
        <Modal.Title className="modal-title">
          <FontAwesomeIcon icon={faCheckSquare} size="4x" color="white" />
          <h2 className="modal-title__h2">Please Agree To Our Terms</h2>
        </Modal.Title>
      </Modal.Header>
      <>
        <Modal.Body>
          <h2 className="modal-body__h2">
            <b>Last Step!</b>
          </h2>
          <p className="modal-body__h2">
            You're one step away from a longer, healthier life
          </p>
          <br></br>
          <label className="modal-body__label">
            <input
              type="checkbox"
              onChange={() => setHandlePrivacy(!handlePrivacy)}
              style={{
                marginRight: "8px",
                marginBottom: "35px",
              }}
            />
            <span className="required-field-marker">*</span>
            <p>
              By providing your email address and/or any other personal
              information, as defined under applicable law, you acknowledge that
              you are agreeing to our use of your information as provided in our{" "}
              <a
                href="/terms"
                target="_blank"
                rel="noreferrer"
                className="a-link"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="a-link"
              >
                Privacy Policy
              </a>
              .
            </p>
            <br></br>
          </label>
          <br></br>
          {hideProgram ? null : (
            <label className="modal-body__label">
              <input
                type="checkbox"
                onChange={() => setHandleFitness(!handleFitness)}
                style={{
                  marginRight: "8px",
                  marginBottom: "25px",
                }}
              />
              <span className="required-field-marker">*</span>
              <p>
                By checking this box, I acknowledge that I have read and agree
                to comply with the terms and conditions of the{" "}
                <a
                  href={
                    config["client"] === "bsca" || config["client"] === "bcbsa"
                      ? `/fitness-program-agreement?clientCode=${config["client"]}`
                      : "/fitness-program-agreement"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="a-link"
                >
                  Program Agreement.
                </a>
              </p>
            </label>
          )}
        </Modal.Body>
      </>
      <Modal.Footer className="modal-footer">
        <div className="line"></div>
        <Button
          disabled={(!handleFitness && !hideProgram) || !handlePrivacy}
          className="agree-terms-modal__button"
          onClick={onAgree}
        >
          <span style={{ color: "White" }}>Agree & Close</span>
        </Button>
      </Modal.Footer>{" "}
    </Modal>
  );
};
export default AgreeTermsModal;
