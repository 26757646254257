import { Form, useFormikContext } from "formik";
import React, { useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { SmartyAddress } from "../../wizard/types";
import "react-toastify/dist/ReactToastify.css";
import AddressVerificationModal from "../../wizard/components/AddressVerificationModal";
import AddressFormProfile from "../../wizard/components/AddressFormProfile";
import { AccountAddress } from "../../../utility/member-portal-api-types";

export interface CurrentAddress {
  id: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  defaultFor?: string[];
}
const ShippingAddress = ({
  verifyOpen,
  submitting,
  setVerifyOpen,
  handle,
  setIsVerifiedAddress,
}: {
  verifyOpen: boolean;
  submitting: boolean;
  setVerifyOpen: (val: boolean) => void;
  handle: (values: AccountAddress) => void;
  setIsVerifiedAddress: (val: boolean) => void;
}) => {
  const { values, setFieldValue, setFieldTouched, handleChange } =
    useFormikContext<AccountAddress>();
  const [, setDisable] = useState(true);

  const setAddressValues = (smartAddressArr: SmartyAddress[]) => {
    if (smartAddressArr.length > 0) {
      //timeouts required by formik :/
      const smartAddress = smartAddressArr[0];
      setFieldValue("address1", smartAddress.address.streetLine);
      setTimeout(() => setFieldTouched("address1", true));
      setFieldValue("address2", smartAddress.address.secondary);
      setTimeout(() => setFieldTouched("address2", true));
      setFieldValue("city", smartAddress.address.city);
      setTimeout(() => setFieldTouched("city", true));
      setFieldValue("state", smartAddress.address.state);
      setTimeout(() => setFieldTouched("state", true));
      setFieldValue("postalCode", smartAddress.address.zipcode);
      setTimeout(() => setFieldTouched("postalCode", true));
      setIsVerifiedAddress(false);
    }
  };

  const handleChangeWrapper = (e: React.ChangeEvent<unknown>) => {
    setDisable(false);
    setIsVerifiedAddress(true);
    handleChange(e);
  };

  const handleInputChange = (input: string) => {
    setFieldValue("address1", input);
    setTimeout(() => setFieldTouched("address1", true));
    setIsVerifiedAddress(true);
    setDisable(false);
  };

  const handleCancel = () => {
    setVerifyOpen(false);
  };
  const handleConfirm = () => {
    handle(values);
  };

  const formatHomeAddress = (): string => {
    return `${values.address1}${
      values.address2 !== "" ? " " + values.address2 : ""
    } ${values.city}, ${values.state} ${values.postalCode}`;
  };

  const getPaymentDisplaySpinner = () => {
    return (
      <Spinner
        style={{ borderWidth: ".1em", width: 15, height: 15 }}
        animation="border"
        as="span"
        size="sm"
      />
    );
  };

  return (
    <>
      <AddressVerificationModal
        homeAddress={formatHomeAddress()}
        billingAddress={undefined}
        show={verifyOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <div className="edit-profile">
        <span>Home Address</span>
        <p className="mb-0">
          This is the address that will show on your invoices and is used to
          calculate tax rates.
        </p>
        <Card.Body className="p-0">
          <Form
            style={{ maxWidth: "516px" }}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            placeholder={undefined}
          >
            <AddressFormProfile
              //select an item from suggestions true
              onChangeSmarty={setAddressValues}
              //every other field apart from street1 false
              onChange={handleChangeWrapper}
              //regular typing in street1 input field  false
              onInputChange={handleInputChange}
              prefix=""
              values={values}
            />
            <Row>
              <Col className="p-0">
                <button
                  type="submit"
                  className="nav-btn-enroll font-weight-bold btn btn-primary p-0"
                  // disabled={disable}
                  onClick={() => {
                    handle;
                  }}
                >
                  {submitting ? getPaymentDisplaySpinner() : "Save Changes"}
                </button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        <div className="vp__personal-info my-4" />
      </div>
    </>
  );
};
export default ShippingAddress;
