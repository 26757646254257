import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { NavLink, useLocation, Link } from "react-router-dom";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  faDownload,
  faPrint,
  faInfoCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { AccountContext } from "../../context/AccountProvider";
import AccountUpdateFailedBanner from "../../components/account/AccountUpdateFailedBanner";
import AddFamilyMember from "../../components/account/AddFamilyMember";
import ArrowRotateLeftSolid from "../../resources/ArrowRotateLeftSolid.svg";
import BundlesDropDown from "../../components/shared/BundlesDropDown";
import CancelSubscriptionModal from "../../components/account/CancelSubscriptionModal";
import DiscardCancellationModal from "../../components/account/DiscardCancellationModal";
import { DiscardPlanChangeModal } from "../../components/account/EditPlan/DiscardPlanChangeModal";
import FamilyStatusModal from "../../components/wizard/components/FamilyStatusModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterCard from "../../components/wizard/components/FooterCard";
import { IAddressFields } from "../../components/wizard/types";
import NonPayrollCheckoutModal from "../../components/wizard/components/NonPayrollCheckoutModal";
import OutStandingBalanceBanner from "../../components/account/OutStandingBalanceBanner";
import { Payment } from "../../types";
import PayrollDeductionModal from "../../components/wizard/components/PayrollDeductionModal";
import PlanSummaryUpdate from "../../components/account/Billing/PlanSummaryUpdate";
import ProcessingOrderModal from "../../components/wizard/components/ProcessingOrderModal";
import ProfileToast from "../../utility/ProfileToast";
import {
  AccountAddress,
  AccountPayment,
  AccountSummaryMember,
  FamilyMember,
  AccountSummaryMemberChanges,
  SubscriptionPreviewResponse,
  DetailedPromoCodeDescription,
} from "../../utility/member-portal-api-types";
import axiosInstance, { axiosBaseInstance } from "../../axios.instance";
import {
  IUser,
  PROGRAM_CODE,
  USER_PAYROLL_STATUS,
  UserContext,
} from "../../context/UserProvider";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { setAddressQuery } from "../../api/identity/Queries";
import { useConfig } from "../../configuration/useConfig";
import { useContext } from "react";
import useSummary from "../../hooks/useSummary";
import { useMembership } from "../../hooks/useMembership";
import { ImpersonationContext } from "../../context/ImpersonationProvider";
import useGetScreenSize from "../../hooks/useGetScreenSize";
import { momentStartOfDay, momentEndOfDate, poll } from "../../utility/util";
import { useAuthToken } from "../../context/Auth0TokenProvider";
import { UnderageFamilyMemberBanner } from "../../components/wizard/components/UnderageFamilyMemberBanner";
import useGetPrograms from "../../hooks/useGetPrograms";
import NoOpBundlesDropDown from "../../components/shared/NoOpBundlesDropDown";
import { Product } from "../../components/wizard/forms/Package";
import { axiosPolling } from "axios-polling";

import DependentCardBody from "../../components/shared/DependentCardBody";
import AddFamilyMemberHCSC from "../../components/account/AddFamilyMemberHCSC";
import {
  calculateAge,
  getUnderageDependents,
} from "../../utility/checkIfUnderAge";
import moment from "moment";
import DiscardUnderagePlanChangeModal from "../../components/account/EditPlan/DiscardUnderagePlanChangeModal";
import InformationDisplay from "../../components/account/InformationDisplay";

export enum ADDRESS {
  SHIPPING = "SHIPPING",
  BILLING = "BILLING",
}

export type AccountCancelRequest = {
  primaryPersonId: number | undefined | null;
  personId: number | undefined | null;
  subscriptionNumber?: string | undefined;
  zuoraAccountNumber?: string | undefined;
};

type DependentCards = {
  personId: number | null;
  cardbase64: string;
};

function useRouterQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function DiscardDisclaimer() {
  const { eligibility } = useMembership();
  const { nextPayment } = useContext(AccountContext);
  const { config } = useConfig();
  const termReasonPattern = /^RS5[0-9]/;
  const termReason = eligibility?.termReason ?? "";

  if (eligibility.isPendingTermination && !termReasonPattern.test(termReason)) {
    if (!eligibility.memberTermDate) {
      throw new Error(
        "Cannot display discard disclaimer without a term date when a cancellation is pending."
      );
    }

    const endOfDayDate = momentEndOfDate().toDate();
    if (
      config["client"] === "hcsc" &&
      moment(eligibility.memberTermDate).toDate() <= endOfDayDate
    ) {
      return <></>;
    }
    if (config["client"] === "mutual-of-omaha") {
      return <></>;
    }
    return (
      <div className="manage-plans__discard-disclaimer">
        Your requested plan cancellation is in progress. If you change your mind
        you can <u> discard this cancellation</u> before{" "}
        {new Date(`${eligibility.memberTermDate}Z`).toLocaleDateString()}.
      </div>
    );
  }

  const { currentSubscription, futureSubscription } = eligibility;

  if (
    !futureSubscription ||
    !nextPayment?.due ||
    futureSubscription?.ratePlanChargeId ===
      currentSubscription?.ratePlanChargeId
  ) {
    return <></>;
  }

  return (
    <div className="manage-plans__discard-disclaimer">
      Your requested plan change is in progress. If you change your mind you can{" "}
      <u>discard changes</u> before{" "}
      <b>
        {new Date(nextPayment.due).toLocaleDateString(undefined, {
          timeZone: "UTC",
        })}
      </b>
      .
    </div>
  );
}

const ManagePlanUpdate = () => {
  const { client, clientConfig, config } = useConfig();
  const { user, setUser } = useContext(UserContext);
  const {
    entitlements,
    memberCardBaseImage,
    nextPayment,
    hasPaymentMethods,
    clientStateCode,
    payrollStatus,
    setAccountDetails,
    memberCanCancel,
    refresh,
  } = useContext(AccountContext);
  const [loadingDependents, setLoadingDependents] = useState<boolean>(false);
  const [loadingAddDependents, setLoadingAddDependents] =
    useState<boolean>(false);
  const [dependents, setDependents] = useState<AccountSummaryMember[]>();
  const { isImpersonated } = useContext(ImpersonationContext);
  const member = useMembership();

  const { isSmallScreen, isMobileScreenSize } = useGetScreenSize();
  const q = useRouterQuery();

  const {
    setPromoCode,
    summary,
    promoLoading,
    setPromoCodeError,
    error: summaryError,
  } = useSummary();
  const currentRatePlanChargeId = member?.eligibilities
    ? member?.eligibilities[0].currentSubscription?.ratePlanChargeId
    : "";

  const futurePlanChargeId = member?.eligibilities
    ? member?.eligibilities[0].futureSubscription?.ratePlanChargeId
    : "";
  const [showNonPayrollCheckoutModal, setShowNonPayrollCheckoutModal] =
    useState<boolean>(false);
  const [openDiscardCancellationModal, setIsDiscardingCancellation] =
    useState<boolean>(false);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openAddHCSCModal, setOpenAddHCSCModal] = useState<boolean>(false);

  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [openPayrollModal, setOpenPayrollModal] = useState<boolean>(false);
  const [reloadToggle, setReloadToggle] = useState<boolean>(false);
  const [isDependentEmailLoading, setIsDependentEmailLoading] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [change, setChange] = useState<string | undefined>(
    currentRatePlanChargeId
  );
  const [dependent, setDependent] = useState<AccountSummaryMember[]>([]);
  const [firstNameCurFamMember, setFirstNameCurFamMember] =
    useState<string>("");
  const [lastNameCurFamMember, setLastNameCurFamMember] = useState<string>("");
  const [emailCurFamMember, setEmailCurFamMember] = useState<string>("");
  const [nextPaymentDate, setnextPaymentDate] = useState<string>("");
  const endOfDayDate = momentEndOfDate().toDate();

  const [promo, setPromoState] = useState("");
  const [promoOpen] = useState(true);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [dependentCards, setDependentCards] = useState<DependentCards[]>([]);
  const [validPromo, setValidPromo] = useState("");
  const [currentPromo, setCurrentPromo] = useState("");
  const [finalPromo, setFinalPromo] = useState("");
  const [paymentMethods, setPaymentMethods] = useState<AccountPayment[] | null>(
    null
  );
  const [defaultpaymentMethod, setDefaultPaymentMethod] =
    useState<AccountPayment | null>(null);

  const [isChange, setIsChange] = useState<boolean>(false);
  const [isUpgrade, setIsUpgrade] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
  const [disableCheckout, setDisableCheckout] = useState<boolean>(false);
  const [couponLoading, setCouponLoading] = useState<boolean>(false);
  const [dependentUpdate, setDependentUpdate] = useState<boolean>(false);
  const [shouldRevertChildOptions, setShouldRevertChildOptions] =
    useState<boolean>(false);

  const [progress, setProgress] = useState(0);
  const [proccessingOrder, setProccessingOrder] = useState(false);
  const [disablePlanSelect, setDisablePlanSelect] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const [errorSummary, setErrorSummary] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedMemberEmail, setSelectedMemberEmail] = useState<string>("");
  const [selectedMemberName, setSelectedMemberName] = useState<string>("");
  const [selectedMemberLastName, setSelectedMemberLastName] =
    useState<string>("");
  const [isDependent, setIsDependent] = useState<boolean>(false);
  const [checkoutDisabled, setCheckoutDisabled] = useState(true);
  const [, setLoadingPayments] = useState(false);
  const [addressChanged, setAddressChanged] = useState<boolean>(false);
  const [isDiscardingPlanChange, setIsDiscardingPlanChange] = useState(false);
  const [isDiscardingUnderagePlanChange, setIsDiscardingUnderagePlanChange] =
    useState<null | number>(null);

  const [filteredDependents, setFilteredDependents] = useState<
    AccountSummaryMember[]
  >([]);
  const [dependentChanges, setDependentChanges] = useState<
    AccountSummaryMemberChanges[]
  >([]);

  const isDAL = clientConfig.isDependentAccessLinked;

  const [newDependents, setNewDependents] = useState<FamilyMember[]>([]);
  const [showUnderageBanner, setShowUnderageBanner] = useState<boolean>(false);
  const [updateUnderageDependent, setUpdateUnderageDependent] =
    useState<boolean>(false);

  const [isStudioOnly, setIsStudioOnly] = useState<boolean>(false);
  const [isLegacyMember, setIsLegacyMember] = useState<boolean>(false);
  const [hasUnderageDependents, setHasUnderageDependents] =
    useState<boolean>(false);
  const [showDiscardCancel, setShowDiscardCancel] = useState<boolean>(true);

  const [removeFamilyDate, setRemoveFamilyDate] = useState<
    string | undefined
  >();

  useEffect(() => {
    setIsLegacyMember(
      member?.eligibilities[0].currentSubscription?.isLegacyProduct ?? false
    );
    if (config["client"] === "hcsc") {
      const primeEligibility = member?.eligibilities.find(
        (e) => e.productCode === "226"
      );
      if (
        primeEligibility &&
        moment(primeEligibility.memberTermDate).toDate() <= endOfDayDate
      ) {
        setShowDiscardCancel(false);
      }
    }
    const filteredEligibilities = member?.eligibilities.filter(
      (eligibility) => {
        return eligibility.isMembershipActive;
      }
    );
    if (
      filteredEligibilities &&
      filteredEligibilities.length == 1 &&
      filteredEligibilities[0].productCode === "260" &&
      filteredEligibilities[0].isMembershipActive
    ) {
      setIsStudioOnly(true);
    }
  });

  type AddDependent = {
    person: {
      subscriberId?: string;
      personId?: number;
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      email: string;
      zip: string;
      relationshipCode: string;
      isUnderage?: boolean;
      isEmailTaken?: boolean;
      clientMemberId: string;
    };
    subscription: {
      ratePlanChargeID?: string;
      programName?: string;
    };
  };

  type MemberRequest = {
    subscriberId?: string;
    personId?: number | null;
    lastName?: string;
    firstName?: string;
    dateOfBirth?: string;
    programCode: string;
    clientMemberId?: string;
    orderActions: [
      {
        currentRatePlanChargeId?: string;
        newRatePlanChargeId?: string;
        ratePlanChargeId?: string;
        currentSubscriptionNumber?: string;
        type: string;
      }
    ];
    billToContact?: {
      lastName: string;
      firstName: string;
      address1: string;
      address2: string;
      city: string;
      country: string;
      postalCode: string;
      state: string;
    };
    soldToContact?: {
      lastName: string;
      firstName: string;
      address1: string;
      address2: string;
      city: string;
      country: string;
      postalCode: string;
      state: string;
      email: string;
    };
  };

  const [addingDependents, setAddingDependents] = useState<AddDependent[]>([]);

  const { error, loading, productRatePlans, addendum, programCode } =
    useGetPrograms();

  const initialPreviewData = {
    immediatePaymentSummary: {
      creditAmountWithoutTax: 0,
      creditAmountWithTax: 0,
      creditTaxAmount: 0,
      immediatePaymentDetails: [
        {
          amountWithoutTax: 0,
          amountWithTax: 0,
          chargeName: "",
          consumerId: "",
          chargeType: "",
          serviceStartDate: "",
          serviceEndDate: "",
          tax: 0,
          tierId: 0,
        },
      ],
      immediateTotalPaymentAmountWithoutTax: 0,
      immediateTotalPaymentAmountWithTax: 0,
      immediateTotalPaymentTaxAmount: 0,
      immediatePaymentDiscountAmountWithoutTax: 0,
      immediatePaymentDiscountTaxAmount: 0,
      immediateTotalPaymentAfterDiscountAmountWithoutTax: 0,
      immediateTotalPaymentAfterDiscountAmountWithTax: 0,
      immediateTotalPaymenAfterDiscountTaxAmount: 0,
      immediateTotalPaymentSetUpFee: 0,
      immediateTotalPaymentRecurringFee: 0,
    },
    monthlyPaymentSummary: {
      monthlyPaymentDetails: [
        {
          amountWithoutTax: 0,
          amountWithTax: 0,
          chargeName: "",
          consumerId: "",
          chargeType: "",
          serviceStartDate: "",
          serviceEndDate: "",
          tax: 0,
          tierId: 0,
        },
      ],
    },
  };

  const token = useAuthToken();
  const [previewData, setPreviewData] =
    useState<SubscriptionPreviewResponse>(initialPreviewData);
  const { eligibility } = useMembership();
  const termReasonPattern = /^RS5[0-9]/;
  const termReason = eligibility?.termReason ?? "";

  const convertToCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(+amount.toFixed(2));
  };
  const [defaultAddress, setDefaultAddress] = useState<AccountAddress | null>(
    null
  );

  const onAddFamilyClose = (selected: FamilyMember[]) => {
    setOpenAddHCSCModal(!openAddHCSCModal);
    if (selected.length > 0) {
      setLoadingAddDependents(true);
      setNewDependents(selected);
    }
  };

  const onAddFamilyCloseWal = () => {
    setOpenAddModal(!openAddModal);
    loadDependents();
  };

  const getProductTierFromRatePlanChargeId = (
    ratePlanChargeId: string | undefined
  ) => {
    if (ratePlanChargeId == undefined) {
      return "3";
    }

    return productRatePlans
      .find((product) => product.recurringRatePlanChargeId === ratePlanChargeId)
      ?.tier.toString();
  };
  useEffect(() => {
    const tempAddingDependents: AddDependent[] = [];
    //if primary is studio only or legacy member, use "digital" plan as default selection for the dependent
    let defaultRatePlanChargeId: string;
    let defaultProgramName: string;
    if (isStudioOnly || isLegacyMember) {
      defaultRatePlanChargeId = productRatePlans[0].recurringRatePlanChargeId;
      defaultProgramName = productRatePlans[0].name;
    } else {
      defaultRatePlanChargeId =
        futurePlanChargeId || change || currentRatePlanChargeId || "";
      defaultProgramName =
        productRatePlans
          .filter((plan) =>
            [
              futurePlanChargeId || change || currentRatePlanChargeId || "",
            ].includes(plan.recurringRatePlanChargeId)
          )
          .map((plan) => plan.name)[0] || "";
    }

    newDependents.map((famMember) => {
      tempAddingDependents.push({
        person: {
          subscriberId: famMember.subscriberId,
          firstName: famMember.firstName,
          lastName: famMember.lastName,
          dateOfBirth: famMember.dateOfBirth,
          email: famMember.personId !== 0 ? famMember.email : "",
          zip: member.postalCode,
          relationshipCode: famMember.relationshipTypeCode,
          isUnderage: famMember.status === "UnderAgeDependent",
          clientMemberId: String(famMember.clientMemberId),
          personId: famMember.personId,
        },
        subscription: {
          ratePlanChargeID: defaultRatePlanChargeId,
          programName: defaultProgramName,
        },
      });
    });
    setAddingDependents(tempAddingDependents);
    setLoadingAddDependents(false);
  }, [newDependents]);

  useEffect(() => {
    addingDependents?.forEach((dependent) => {
      if (dependent.subscription.programName !== PROGRAM_CODE.Studio) {
        previewFamilySubmit();
      } else {
        setIsChange(false);
      }
    });
  }, [
    addingDependents.length,
    ...addingDependents.map((dep) => dep.subscription.ratePlanChargeID),
  ]);

  useEffect(() => {
    setShowUnderageBanner(false);
    addingDependents.map((dep) => {
      if (dep.person.isUnderage) {
        setShowUnderageBanner(true);
      }
    });
  }, [
    addingDependents.length,
    ...addingDependents.map((dep) => dep.person.isUnderage),
  ]);

  useEffect(() => {
    if (filteredDependents && clientConfig) {
      const underAgeDependents = getUnderageDependents(
        filteredDependents,
        clientConfig.minBuyerMemberAge
      );
      setHasUnderageDependents(underAgeDependents ? true : false);
      setShowUnderageBanner(underAgeDependents ? true : false);
    }
  }, [filteredDependents, clientConfig]);

  useEffect(() => {
    if (addingDependents.length == 0) {
      setDisableCheckout(loadingPreview);
    } else {
      let allValid = true;
      addingDependents.map((dep) => {
        if (dep.person.isEmailTaken || !isValidEmail(dep.person.email)) {
          allValid = false;
        }
      });

      setDisableCheckout(loadingPreview || !allValid);
    }
  }, [
    loadingPreview,
    addingDependents.length,
    ...addingDependents.map((dep) => dep.person.email),
  ]);

  const onRemoveAddMember = (idx) => {
    const updatedAddingDependents = addingDependents
      .slice(0, idx)
      .concat(addingDependents.slice(idx + 1));
    if (
      updatedAddingDependents.length == 0 &&
      change != currentRatePlanChargeId
    ) {
      previewTierSubmit(change);
    } else if (updatedAddingDependents.length == 0) {
      {
        setPreviewData(initialPreviewData);
      }
    }
    setAddingDependents(updatedAddingDependents);
  };

  const filterProductRateplans = (
    productRatePlans: Product[],
    addDep: AddDependent,
    change?: string
  ) => {
    if (addDep.person.isUnderage && isLegacyMember) {
      return productRatePlans.filter((plan) => {
        return plan.tier < 3;
      });
    }
    if (addDep.person.isUnderage && programCode == PROGRAM_CODE.PrimeSTD) {
      const tierStr =
        getProductTierFromRatePlanChargeId(change) ||
        member.eligibilities[0].currentSubscription?.tier ||
        "0";

      const tier = parseInt(tierStr);

      return productRatePlans.filter((plan) => {
        if (tier <= 3) {
          return plan.tier <= tier;
        } else {
          return plan.tier <= 3 || plan.tier === tier;
        }
      });
    }
    if (addDep.person.isUnderage && programCode == PROGRAM_CODE.Studio) {
      return productRatePlans.filter(
        (plan) => plan.name == PROGRAM_CODE.Studio
      );
    }
    return productRatePlans;
  };

  const checkEmailExistance = (email, idx) => {
    axiosInstance
      .get(`/check-email/${encodeURIComponent(email)}`, {})
      .then(({ data }) => {
        let duplicate = false;
        addingDependents.map((item, index) => {
          if (email === item.person.email && index != idx) {
            duplicate = true;
          }
        });
        const updatedAddingDeps = addingDependents.map((item, index) => {
          if (index === idx) {
            item.person.isEmailTaken = data.doesEmailExist || duplicate;
          }
          return item;
        });
        setAddingDependents(updatedAddingDeps);
      })
      .catch((e) => {
        console.log(e.toJSON());
      });
  };

  const loadDependents = () => {
    if (config["client"] === "hcsc" || config["client"] === "walmart") {
      setLoadingDependents(true);
      axiosInstance
        .request({
          url: `/account/dependents/`,
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        })
        .then((response) => {
          setDependents(response.data);
          setLoadingDependents(false);
        });
    }
  };

  useEffect(() => {
    loadDependents();
  }, []);

  useEffect(() => {
    setDefaultAddress(member.subscriptionAccount?.shipping ?? null);
    setDefaultPaymentMethod(
      member?.subscriptionAccount?.paymentMethods?.find(
        (item) => item.isDefaultPaymentMethod === true
      ) ?? null
    );
    setPaymentMethods(member?.subscriptionAccount?.paymentMethods ?? null);
  }, []);

  useEffect(() => {
    if (q.get("edit_success") === "true") {
      setTimeout(() => {
        ProfileToast(`Your account was successfully updated`, true, client);
      }, 250);
    }
  }, []);

  useEffect(() => {
    if (dependents?.length) {
      const dependentsFiltered = dependents?.filter((dep) => {
        let keepDependent = true;
        if (!dep.eligibilities[0].isMembershipActive) {
          keepDependent = false;
        } else if (dep.eligibilities[0].isPrimary === true) {
          keepDependent = false;
        }
        return keepDependent;
      });
      setFilteredDependents(dependentsFiltered);
    }
  }, [dependents]);

  useEffect(() => {
    if (member?.eligibilities[0].futureSubscription) {
      setDisablePlanSelect(
        !Object.entries(member?.eligibilities[0].futureSubscription).length ===
          false
      );
    } else if (member?.eligibilities[0].futureSubscription === null) {
      setDisablePlanSelect(false);
    }
    if (isImpersonated && payrollStatus === USER_PAYROLL_STATUS.Payroll) {
      setDisablePlanSelect(true);
    }
  }, [
    member?.eligibilities[0].futureSubscription,
    isImpersonated,
    payrollStatus,
  ]);

  useEffect(() => {
    if (change !== currentRatePlanChargeId) {
      if (hasUnderageDependents) {
        setUpdateUnderageDependent(true);
      } else {
        if (dependentChanges.length !== 0) {
          previewTierSubmitDependents();
        } else if (addingDependents.length > 0) {
          previewFamilySubmit();
        } else {
          previewTierSubmit(change);
        }
        setIsChange(true);
      }
    } else if (dependentChanges.length !== 0) {
      previewTierSubmitDependents();
    } else {
      setIsChange(false);
      setPreviewData(initialPreviewData);
    }
  }, [change]);

  const [isRemovingMember, setIsRemovingMember] = useState(false);
  const canChangePlan = useMemo(() => {
    const currentPlanChargeId =
      eligibility.currentSubscription?.ratePlanChargeId;
    if (eligibility.isPendingTermination) {
      return false;
    }

    if (!eligibility.futureSubscription) {
      return true;
    }
    // TODO - this checking of the current and future
    // ratePlanChargeIds should be not necessary, but at
    // the time of writing there is an issue where the
    // futureSubscription is not being set to null when
    // discarding a change. This has the same effect.
    if (currentPlanChargeId === futurePlanChargeId) {
      return true;
    }

    const currentTier = parseInt(eligibility.currentSubscription?.tier || "0");
    const futureTier = parseInt(eligibility.futureSubscription?.tier || "0");
    return currentTier < futureTier;
  }, [eligibility]);

  const previewTierSubmit = (newRatePlanChargeId, promo?) => {
    setLoadingPreview(true);
    const membersBody = [
      {
        personId: member?.personId,
        payrollStatus: payrollStatus,
        programCode,
        orderActions: [
          user.isPayroll
            ? {
                currentRatePlanChargeId: currentRatePlanChargeId,
                newRatePlanChargeId: newRatePlanChargeId,
                type: "SubscriptionUpdate",
              }
            : {
                currentRatePlanChargeId: currentRatePlanChargeId,
                newRatePlanChargeId: newRatePlanChargeId,
                currentSubscriptionNumber:
                  member?.eligibilities[0].currentSubscription
                    ?.subscriptionNumber,
                type: "SubscriptionUpdate",
              },
        ],
      },
    ];
    setLoadingPreview(true);
    setErrorSummary(false);
    axiosInstance
      .post(
        "/account/subscriptions/preview",
        {
          members: membersBody,
          promoCodes: promo
            ? promo.includes(",")
              ? promo.split(",").map((code) => code.trim())
              : [promo]
            : [],
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          const { data } = response;
          setPreviewData(data);
          setPromoCodeError(data);
          if (data.promoCodeError.length !== 0) {
            setValidPromo("FALSE");
          }
          if (
            data.immediatePaymentSummary.immediatePaymentDetails[0]?.chargeName
          ) {
            setIsUpgrade(true);
          } else {
            setIsUpgrade(false);
          }
          // PENDING
          setLoadingPreview(false);
          setIsFailed(false);
          setIsChange(true);
        } else {
          setLoadingPreview(false);
          setIsFailed(true);
        }
      })
      .catch(() => {
        setLoadingPreview(false);
        setErrorSummary(true);
      });
  };

  const previewFamilySubmit = (promo?) => {
    setLoadingPreview(true);
    setErrorSummary(false);

    const memberArr: MemberRequest[] = [];

    if (change && change != currentRatePlanChargeId) {
      memberArr.push({
        personId: member?.personId,
        programCode,
        orderActions: [
          {
            currentRatePlanChargeId: currentRatePlanChargeId,
            newRatePlanChargeId: change,
            currentSubscriptionNumber:
              member?.eligibilities[0].currentSubscription?.subscriptionNumber,
            type: "SubscriptionUpdate",
          },
        ],
      });
    }

    addingDependents.forEach((addDep) => {
      if (addDep.subscription.programName !== PROGRAM_CODE.Studio) {
        memberArr.push({
          ...(addDep.person.personId !== 0
            ? { personId: addDep.person.personId }
            : {}),
          lastName: addDep.person.lastName,
          firstName: addDep.person.firstName,
          dateOfBirth: addDep.person.dateOfBirth,
          programCode: programCode,
          clientMemberId: String(addDep.person.clientMemberId),
          orderActions: [
            {
              ratePlanChargeId: addDep.subscription.ratePlanChargeID,
              type:
                addDep.person.personId !== 0 ? "SubscriptionNew" : "NewAccount",
            },
          ],
          billToContact: {
            lastName: member.lastName,
            firstName: member.firstName,
            address1: member.address1,
            address2: member.address2 ? member.address2 : "",
            city: member.city,
            country: "United States",
            postalCode: member.postalCode,
            state: member.state,
          },
          soldToContact: {
            lastName: addDep.person.lastName,
            firstName: addDep.person.firstName,
            address1: member.address1,
            address2: member.address2 ? member.address2 : "",
            city: member.city,
            country: "United States",
            postalCode: member.postalCode,
            state: member.state,
            email: addDep.person.email ? addDep.person.email : member.email,
          },
        });
      }
    });
    if (memberArr.length > 0) {
      axiosInstance
        .post(
          "/account/subscriptions/preview",
          {
            members: memberArr,
            promoCodes: promo
              ? promo.includes(",")
                ? promo.split(",").map((code) => code.trim())
                : [promo]
              : [],
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            const { data } = response;
            setPreviewData(data);
            setPromoCodeError(data);
            if (data.promoCodeError.length !== 0) {
              setValidPromo("FALSE");
            }
            if (
              data.immediatePaymentSummary.immediatePaymentDetails[0]
                ?.chargeName
            ) {
              setIsUpgrade(true);
            } else {
              setIsUpgrade(false);
            }
            // PENDING
            setLoadingPreview(false);
            setIsFailed(false);
            setIsChange(true);
          } else {
            setLoadingPreview(false);
            setIsFailed(true);
          }
        })
        .catch(() => {
          setLoadingPreview(false);
          setErrorSummary(true);
        });
    }
  };

  const handleConfirmAddFamily = (paymentMethodId) => {
    setShowNonPayrollCheckoutModal(false);
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 10), 300));
    setProccessingOrder(true);
    const memberArr: MemberRequest[] = [];

    addingDependents.map((addDep) => {
      memberArr.push({
        subscriberId: addDep.person.subscriberId,
        ...(addDep.person.personId !== 0
          ? { personId: addDep.person.personId }
          : {}),
        lastName: addDep.person.lastName,
        firstName: addDep.person.firstName,
        dateOfBirth: addDep.person.dateOfBirth,
        programCode,
        clientMemberId: String(addDep.person.clientMemberId),
        orderActions: [
          {
            ratePlanChargeId: addDep.subscription.ratePlanChargeID
              ? addDep.subscription.ratePlanChargeID
              : "",
            type:
              addDep.person.personId !== 0 ? "SubscriptionNew" : "NewAccount",
          },
        ],
        billToContact: {
          lastName: member.lastName,
          firstName: member.firstName,
          address1: member.address1,
          address2: member.address2 ? member.address2 : "",
          city: member.city,
          country: "United States",
          postalCode: member.postalCode,
          state: member.state,
        },
        soldToContact: {
          lastName: addDep.person.lastName,
          firstName: addDep.person.firstName,
          address1: member.address1,
          address2: member.address2 ? member.address2 : "",
          city: member.city,
          country: "United States",
          postalCode: member.postalCode,
          state: member.state,
          email: addDep.person.email ? addDep.person.email : member.email,
        },
      });
    });
    axiosInstance
      .put(
        "/account/subscriptions",
        {
          paymentMethodId: paymentMethodId,
          members: memberArr,
          promoCodes: finalPromo
            ? finalPromo.includes(",")
              ? finalPromo.split(",").map((code) => code.trim())
              : [finalPromo]
            : [],
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        if (response.data) {
          axiosPolling(axiosInstance, {
            retryLimit: 15,
            delay: 5000,
          });

          // create polling
          const { emit, on, off, remove } = axiosInstance.poll({
            url: `/account/subscriptions/status/${response.data.executionId}`,
          });
          // send polling
          // Transmit config will be merged with poll config, transmit config takes precedence
          emit();
          const timer = setTimeout(() => {
            // console.log("This will run after 60 seconds!");
            setIsFailed(true);
            setProccessingOrder(false);
            off();
            // remove all on
            remove();
          }, 120000);
          // watch request
          on("response", (response) => {
            if ((response.data.status as unknown as string) == "SUCCESS") {
              clearTimeout(timer);
              resetAccountAndManagePlan();
              off();
              // remove all on
              remove();
            }
            if ((response.data.status as unknown as string) == "FAILED") {
              clearTimeout(timer);
              setIsFailed(true);
              setProccessingOrder(false);
              off();
              remove();
            }
          });
          // watch error
          on("error", (error) => {
            setIsFailed(true);
            setProccessingOrder(false);
            console.log(error);
          });
        }
      })
      .catch(() => {
        setProccessingOrder(false);
        setErrorSummary(true);
      });
  };

  const onAdd = () => {
    refresh().then(() => {
      ProfileToast(`Your account was successfully updated`, true, "success");
    });
    // getAccountSummary();
  };

  const findCurrentFamMember = (email) => {
    return dependents!.find((item) => item.email === email);
  };

  const handleCurrentFamMember = (email) => {
    const cur = findCurrentFamMember(email);
    setFirstNameCurFamMember(cur!.firstName);
    setLastNameCurFamMember(cur!.lastName);
    setEmailCurFamMember(cur!.email);
  };

  // const getAccountSummary = async () => {
  //   await axiosInstance
  //     .get("/account/summary", {
  //       headers: {
  //         accept: "application/json",
  //         Authorization: `Bearer ${user.token}`,
  //       },
  //     })
  //     .then(({ data }) => {
  //       if (data) {
  //         setAccountDetails(data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error.toJSON());
  //     });
  // };

  type DeleteBody = {
    primaryPersonId: number | undefined | null;
    personId: number | undefined | null;
    subscriptionNumber?: string | undefined;
    zuoraAccountNumber?: string | undefined;
    termDate: string | undefined;
  };

  const findDependent = () => {
    return dependents?.find((item) => {
      return item.email === emailCurFamMember;
    });
  };

  const cancellingMember = () => {
    const body: DeleteBody = {
      primaryPersonId: member?.personId,
      personId: !isDependent ? member!.personId : findDependent()?.personId,
      termDate: nextPaymentDate,
    };
    if (member?.eligibilities[0].isPrimary && !user.isPayroll) {
      body.subscriptionNumber =
        member?.eligibilities[0].currentSubscription?.subscriptionNumber;
      body.zuoraAccountNumber = member?.subscriptionAccount?.accountNumber;
    }
    if (isDependent && !isDAL) {
      body.subscriptionNumber =
        findDependent()?.eligibilities[0].currentSubscription?.subscriptionNumber;
      body.zuoraAccountNumber =
        findDependent()?.subscriptionAccount?.accountNumber;
    }

    const cancelMember = async () => {
      await axiosInstance
        .post("/account/cancel", body, {
          headers: {
            accept: "application/json",
          },
        })
        .then(async (response) => {
          if (response.data) {
            axiosPolling(axiosInstance, {
              retryLimit: 15,
              delay: 5000,
            });

            // create polling
            const { emit, on, off, remove } = axiosInstance.poll({
              url: `/account/cancel/status/${response.data.executionId}`,
            });
            // send polling
            // Transmit config will be merged with poll config, transmit config takes precedence
            emit();
            const timer = setTimeout(() => {
              // console.log("This will run after 60 seconds!");
              setIsFailed(true);
              setIsRemovingMember(false);
              setOpenRemoveModal(false);
              off();
              // remove all on
              remove();
            }, 120000);
            // watch request
            on("response", (response) => {
              if ((response.data.status as unknown as string) == "SUCCESS") {
                clearTimeout(timer);
                resetAccountAndManagePlan();
                setDependentUpdate(!dependentUpdate);
                setIsFailed(false);
                ProfileToast(
                  `Your account was successfully updated`,
                  true,
                  client
                );
                setIsRemovingMember(false);
                setOpenRemoveModal(false);
                off();
                // remove all on
                remove();
                return;
              }
              if ((response.data.status as unknown as string) == "FAILED") {
                clearTimeout(timer);
                setIsFailed(true);
                setIsRemovingMember(false);
                setOpenRemoveModal(false);
                off();
                remove();
                return;
              }
            });
            // watch error
            on("error", (error) => {
              setIsFailed(true);
              setIsRemovingMember(false);
              setOpenRemoveModal(false);
              console.log(error);
            });
          }
        })
        .catch((e) => console.log(e));
    };
    cancelMember();
  };

  const onSuccess = () => {
    setDependent(dependent.filter((item) => item.email !== emailCurFamMember));
    setIsRemovingMember(true);
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 10), 300));
    cancellingMember();
    setReloadToggle(!reloadToggle);
    setIsDependent(false);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const findDependentEditEmail = () => {
    return dependents?.find((item) => {
      return item.email === selectedMemberEmail;
    });
  };

  const changeDependentEmail = (newEmail) => {
    return dependents?.find((item) => {
      if (item.email === selectedMemberEmail) {
        item.email = newEmail;
      }
    });
  };

  const handleEmailChange = (newEmail) => {
    setIsDependentEmailLoading(true);
    const dependentId = findDependentEditEmail()?.personId;

    const body = {
      email: newEmail,
    };
    axiosInstance
      .request({
        url: `/account/dependents/${dependentId}`,
        data: body,
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsDependentEmailLoading(false);
        setErrorMessage("");
        if (response.status === 200) {
          if (response.data && response.data.outcome) {
            if (response.data.outcome === "NoChanges") {
              setErrorMessage("200-NoChanges");
            } else if (response.data.outcome === "AccountCreated") {
              setIsFailed(false);
              ProfileToast(
                "Account Setup Invite has been successfully sent",
                true,
                client
              );
              changeDependentEmail(newEmail);
              toggleEditModalHandler();
            } else if (response.data.outcome === "EmailChanged") {
              setIsFailed(false);
              ProfileToast(
                "Your account was successfully updated",
                true,
                client
              );
              changeDependentEmail(newEmail);
              toggleEditModalHandler();
            }
          }
        } else if (response.status === 401 || response.status === 500) {
          setIsFailed(true);
          toggleEditModalHandler();
        } else if (response.status === 409) {
          setErrorMessage("409-Error");
        } else {
          setIsFailed(true);
        }
      })
      .catch((e) => {
        setIsDependentEmailLoading(false);
        console.log(e);
        if (e.response.status === 400) {
          setIsFailed(true);
        } else if (e.response.status === 409) {
          setIsFailed(true);
          setErrorMessage("409-Error");
        } else if (e.response.status === 500 || e.response.status === 401) {
          setIsFailed(true);
          toggleEditModalHandler();
        } else {
          setIsFailed(true);
        }
      });
  };

  const toggleModalHandler = () => {
    if (clientConfig.isDependentAccessLinked) {
      setOpenAddModal(!openAddModal);
    } else {
      setOpenAddHCSCModal(!openAddHCSCModal);
    }
  };
  const toggleRemoveModalHandler = () => {
    setOpenRemoveModal(!openRemoveModal);
    setIsDependent(false);
  };
  const toggleEditModalHandler = () => {
    setErrorMessage("");
    setOpenEditModal(!openEditModal);
  };

  const toggleDiscardCancellationModalHandler = () => {
    setIsDiscardingCancellation(true);
  };

  const handlePromo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoState(e.target.value);
  };

  const togglePromoModal = () => setShowPromoModal((prev) => !prev);

  const validatePromoCode = async (
    promoCode: string,
    currentSubscriptionNumber?: string
  ) => {
    let response;
    //Need to check if there is pending adding dependents here
    if (currentSubscriptionNumber) {
      response = await axiosInstance.post(`/account/subscriptions/preview`, {
        members: [
          {
            personId: member.personId,
            orderActions: [
              {
                type: "AddPromoCode",
                promoCode,
                currentSubscriptionNumber,
              },
            ],
          },
        ],
      });
    } else if (addingDependents.length > 0) {
      previewFamilySubmit(promoCode);
    } else {
      previewTierSubmit(change, promoCode);
    }

    currentSubscriptionNumber &&
      setValidPromo(response.data.success ? "TRUE" : "FALSE");
    if (!response.data.success) {
      return;
    }

    currentSubscriptionNumber && setPreviewData(response.data);
    togglePromoModal();
  };

  const applyPromoCode = async (
    promoCode: string,
    currentSubscriptionNumber: string
  ) => {
    if (!promoCode || !currentSubscriptionNumber) return false;
    //here we need to check if the user is also adding a family member and use the latest preview post data.
    if (addingDependents.length > 0) {
      previewFamilySubmit();
    } else {
      await axiosInstance
        .put(`/account/subscriptions`, {
          members: [
            {
              orderActions: [
                {
                  type: "AddPromoCode",
                  promoCode,
                  currentSubscriptionNumber,
                },
              ],
            },
          ],
        })
        .then(async (response) => {
          if (response.data) {
            axiosPolling(axiosInstance, {
              retryLimit: 15,
              delay: 5000,
            });

            // create polling
            const { emit, on, off, remove } = axiosInstance.poll({
              url: `/account/subscriptions/status/${response.data.executionId}`,
            });
            // send polling
            // Transmit config will be merged with poll config, transmit config takes precedence
            emit();
            const timer = setTimeout(() => {
              // console.log("This will run after 60 seconds!");
              off();
              // remove all on
              remove();
            }, 120000);
            // watch request
            on("response", (response) => {
              if ((response.data.status as unknown as string) == "SUCCESS") {
                setPromoCode("");
                resetAccountAndManagePlan();
                clearTimeout(timer);
                setIsFailed(false);
                ProfileToast(
                  `Your account was successfully updated`,
                  true,
                  client
                );
                off();
                // remove all on
                remove();
              }
              if ((response.data.status as unknown as string) == "FAILED") {
                setLoadingPreview(false);
                clearTimeout(timer);
                setPreviewData(initialPreviewData);
                setIsFailed(true);
                off();
                remove();
              }
            });
            // watch error
            on("error", (error) => {
              setIsFailed(true);
              setProccessingOrder(false);
              console.log(error);
            });
          }
        })
        .catch(() => {
          setProccessingOrder(false);
          setErrorSummary(true);
        });
    }
  };

  const onCancelPromoCode = () => {
    togglePromoModal();
    setPreviewData(initialPreviewData);
  };
  const onApplyPromoCode = async () => {
    try {
      togglePromoModal();
      setLoadingPreview(true);
      await applyPromoCode(promo, getUserSubscriptionNumber(user) || ""); //here is possible missing sending the add family member
    } catch (error) {}
  };

  const getUserSubscriptionNumber = (user: IUser) =>
    user.AccountSummaryMember?.eligibilities[0].currentSubscription
      ?.subscriptionNumber;

  const handlePromoSubmit = async () => {
    try {
      setValidPromo("");
      setIsFailed(false);
      setCouponLoading(true);
      setCurrentPromo(promo);

      if (summary?.promoCodes.length === 0) {
        setPromoCode(promo);
      } else {
        const str = summary?.promoCodes
          .map(function (elem) {
            if (elem.code !== promo) return elem.code;
          })
          .join(",");
        setPromoCode(promo + "," + str);
      }
      await validatePromoCode(promo, getUserSubscriptionNumber(user) || "");
    } catch (error: unknown) {
      setCurrentPromo("");
    } finally {
      setCouponLoading(false);
    }
  };

  const handlePreviewPromoSubmit = async () => {
    try {
      setValidPromo("");
      setIsFailed(false);
      setLoadingPreview(true);
      setCurrentPromo(promo);

      const uniquePromoCodes = Array.from(
        new Set([
          ...(
            previewData.immediatePaymentSummary?.detailedPromoCodeDescription ??
            []
          )
            .filter(
              (promoCode: DetailedPromoCodeDescription) =>
                !promoCode.isSystemApplied
            )
            .map(
              (promoCode: DetailedPromoCodeDescription) =>
                promoCode.promoCodeUsed ?? ""
            ),
          ...(
            previewData.monthlyPaymentSummary?.detailedPromoCodeDescription ??
            []
          )
            .filter(
              (promoCode: DetailedPromoCodeDescription) =>
                !promoCode.isSystemApplied
            )
            .map(
              (promoCode: DetailedPromoCodeDescription) =>
                promoCode.promoCodeUsed ?? ""
            ),
        ])
      );

      if (uniquePromoCodes.length === 0) {
        setPromoCode(promo);
        setFinalPromo(promo);
        await validatePromoCode(promo);
      } else {
        const str = uniquePromoCodes
          .map(function (elem) {
            if (elem !== promo) return elem;
          })
          .join(",");
        setPromoCode(promo + "," + str);
        setFinalPromo(promo + "," + str);
        await validatePromoCode(promo + ", " + str);
      }
    } catch (error: unknown) {
      setCurrentPromo("");
    }
  };

  const handlePromoRemove = (deletedPromo: string) => {
    if (summary?.promoCodes.length === 1) {
      setPromoCode("");
    } else {
      const str = summary?.promoCodes
        .map(function (elem) {
          if (elem.code !== deletedPromo) return elem.code;
        })
        .join(",");
      const removed = str?.replace(/(^,)|(,$)/g, "");
      setPromoCode(removed + "");
    }
  };

  const handleCancelChangeTier = () => {
    setPreviewData(initialPreviewData);
    setIsChange(false);
    setProccessingOrder(false);
    setChange(eligibility.currentSubscription?.ratePlanChargeId);
    setShouldRevertChildOptions(true);
  };

  const setAddress = async (values: IAddressFields) => {
    if (addressChanged) {
      try {
        setAddressQuery({
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email,
          address1: values.street1,
          address2: values.street2,
          city: values.city,
          state: values.state,
          zipCode: values.zip,
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const clearAddDeps = () => {
    setAddingDependents([]);
  };

  const resetAccountAndManagePlan = async () => {
    await axiosInstance("/account/summary", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.data) {
        axiosInstance
          .get(`/account/next-payment-info`, {})
          .then((upcomingResponse) => {
            const newAccountData = {
              ...response.data,
              nextPayment: {
                amount: upcomingResponse.data.amount,
                due: upcomingResponse.data.nextPaymentDate,
                overdue: upcomingResponse.data.isBalanceOverdue,
              },
            };
            setAccountDetails(newAccountData);

            setPreviewData(initialPreviewData);
            setChange(
              response.data.member?.eligibilities[0]?.currentSubscription
                ?.ratePlanChargeId
            );
            setIsChange(false);
            setUser({
              AccountSummaryMember: response.data.member,
            });
            setProccessingOrder(false);
            setLoadingPreview(false);
          });
        loadDependents();
        clearAddDeps();
      }
    });
  };

  const handleConfirmChangeTierPayroll = () => {
    setOpenPayrollModal(false);
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 10), 300));
    setProccessingOrder(true);

    axiosInstance
      .put(
        "/account/subscriptions",
        {
          members: [
            {
              personId: member?.personId,
              clientStateCode: clientStateCode,
              subscriberId: member?.subscriptionAccount?.id,
              programCode,
              payrollStatus: payrollStatus,
              orderActions: [
                {
                  currentRatePlanChargeId: currentRatePlanChargeId,
                  newRatePlanChargeId: change,
                  type: "SubscriptionUpdate",
                },
              ],
            },
          ],
          promoCodes: finalPromo
            ? finalPromo.includes(",")
              ? finalPromo.split(",").map((code) => code.trim())
              : [finalPromo]
            : [],
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        if (response.data) {
          axiosPolling(axiosInstance, {
            retryLimit: 15,
            delay: 5000,
          });

          // create polling
          const { emit, on, off, remove } = axiosInstance.poll({
            url: `/account/subscriptions/status/${response.data.executionId}`,
          });
          // send polling
          // Transmit config will be merged with poll config, transmit config takes precedence
          emit();
          const timer = setTimeout(() => {
            // console.log("This will run after 60 seconds!");
            setIsFailed(true);
            setProccessingOrder(false);
            off();
            // remove all on
            remove();
          }, 120000);
          // watch request
          on("response", (response) => {
            if ((response.data.status as unknown as string) == "SUCCESS") {
              clearTimeout(timer);
              resetAccountAndManagePlan();
              off();
              // remove all on
              remove();
            }
            if ((response.data.status as unknown as string) == "FAILED") {
              clearTimeout(timer);
              setIsFailed(true);
              setProccessingOrder(false);
              off();
              remove();
            }
          });
          // watch error
          on("error", (error) => {
            setIsFailed(true);
            setProccessingOrder(false);
            console.log(error);
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const handleConfirmCheckout = (paymentMethodId) => {
    handleConfirmChangeTier(paymentMethodId);

    if (addDependentList.length > 0) {
      handleConfirmAddFamily(paymentMethodId);
    }
  };
  const handleConfirmChangeTier = (paymentMethodId) => {
    const testBody: unknown[] = [];
    [];
    const primaryBody = {
      payrollStatus: payrollStatus,
      personId: member?.personId,
      programCode,
      orderActions: [
        user.isPayroll
          ? {
              currentRatePlanChargeId: currentRatePlanChargeId,
              newRatePlanChargeId:
                change !== currentRatePlanChargeId
                  ? change
                  : currentRatePlanChargeId,
              type:
                change !== currentRatePlanChargeId
                  ? "SubscriptionUpdate"
                  : "NoAction",
            }
          : {
              currentRatePlanChargeId: currentRatePlanChargeId,
              newRatePlanChargeId:
                change !== currentRatePlanChargeId
                  ? change
                  : currentRatePlanChargeId,
              currentSubscriptionNumber:
                member?.eligibilities[0].currentSubscription
                  ?.subscriptionNumber,
              type:
                change !== currentRatePlanChargeId
                  ? "SubscriptionUpdate"
                  : "NoAction",
            },
      ],
    };
    if (change !== currentRatePlanChargeId) testBody.push(primaryBody);

    dependentChanges.map((dependent) => {
      return testBody.push({
        payrollStatus: payrollStatus,
        personId: dependent.personId,
        programCode,
        orderActions: [
          user.isPayroll
            ? {
                currentRatePlanChargeId:
                  dependent.eligibilities[0].currentSubscription
                    ?.ratePlanChargeId,
                newRatePlanChargeId: dependent.newRatePlanChargeId, // Use the newRatePlanChargeId variable
                type: "SubscriptionUpdate",
              }
            : {
                currentRatePlanChargeId:
                  dependent.eligibilities[0]?.currentSubscription
                    ?.ratePlanChargeId,
                newRatePlanChargeId: dependent.newRatePlanChargeId, // Use the newRatePlanChargeId variable
                currentSubscriptionNumber:
                  dependent?.eligibilities[0]?.currentSubscription
                    ?.subscriptionNumber,
                type: "SubscriptionUpdate",
              },
        ],
      });
    });
    if (testBody.length === 0) return;
    //TODO:
    setShowNonPayrollCheckoutModal(false);
    setProgress(0);
    setIntervalId(setInterval(() => setProgress((p) => p + 10), 300));
    setProccessingOrder(true);
    axiosInstance
      .put(
        "/account/subscriptions",
        {
          paymentMethodId: paymentMethodId,
          members: [...testBody],
          promoCodes: finalPromo
            ? finalPromo.includes(",")
              ? finalPromo.split(",").map((code) => code.trim())
              : [finalPromo]
            : [],
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        if (response.data) {
          axiosPolling(axiosInstance, {
            retryLimit: 15,
            delay: 5000,
          });

          // create polling
          const { emit, on, off, remove } = axiosInstance.poll({
            url: `/account/subscriptions/status/${response.data.executionId}`,
          });
          // send polling
          // Transmit config will be merged with poll config, transmit config takes precedence
          emit();
          const timer = setTimeout(() => {
            // console.log("This will run after 60 seconds!");
            setIsFailed(true);
            setProccessingOrder(false);
            off();
            // remove all on
            remove();
          }, 120000);
          // watch request
          on("response", (response) => {
            if ((response.data.status as unknown as string) == "SUCCESS") {
              clearTimeout(timer);
              resetAccountAndManagePlan();
              off();
              // remove all on
              remove();
            }
            if ((response.data.status as unknown as string) == "FAILED") {
              clearTimeout(timer);
              setIsFailed(true);
              setProccessingOrder(false);
              off();
              remove();
            }
          });
          // watch error
          on("error", (error) => {
            setIsFailed(true);
            setProccessingOrder(false);
            console.log(error);
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const pTier = parseInt(
      getProductTierFromRatePlanChargeId(e.target.value) ?? "0"
    );

    // ensure rpcId of underage deps are still properly set on primary plan change
    let newChanges = false;
    const newDependents = addingDependents.map((dep) => {
      if (dep.person.isUnderage) {
        const dTier = parseInt(
          getProductTierFromRatePlanChargeId(
            dep.subscription.ratePlanChargeID
          ) ?? "0"
        );

        if (
          (pTier <= 3 && pTier < dTier) ||
          (pTier > 3 && dTier !== pTier && dTier > 3)
        ) {
          newChanges = true;
          dep.subscription.ratePlanChargeID = e.target.value;
        }
      }
      return dep;
    });

    if (newChanges) {
      setAddingDependents(newDependents);
    }

    setChange(e.target.value);
  };
  useEffect(() => {
    // if (!updateUnderageDependent) {
    if (dependentChanges.length !== 0) {
      previewTierSubmitDependents();
    } else if (change !== currentRatePlanChargeId) {
      previewTierSubmit(change);
    } else {
      setPreviewData(initialPreviewData);
      setLoadingPreview(false);
      setErrorSummary(false);
    }
    // }
  }, [dependentChanges]);

  const handleSelectingMember = (dependent: AccountSummaryMember) => {
    setSelectedMemberEmail(dependent.email);
    setSelectedMemberName(dependent.firstName);
    setSelectedMemberLastName(dependent.lastName);
    toggleEditModalHandler();
  };
  const handleDependentPlanChange = (
    dependentNewRatePlan: string,
    dependent: AccountSummaryMember
  ) => {
    if (dependentChanges.length === 0) {
      setDependentChanges([
        {
          ...dependent,
          newRatePlanChargeId: dependentNewRatePlan,
        },
      ]);
    } else {
      const dependentsWithChange: AccountSummaryMemberChanges[] = [];
      let isDependentAlreadyAdded = false;
      dependentChanges.forEach((dep) => {
        if (dep.personId === dependent.personId) {
          isDependentAlreadyAdded = true;
          if (
            dep.eligibilities[0].currentSubscription?.ratePlanChargeId !==
            dependentNewRatePlan
          ) {
            dependentsWithChange.push({
              ...dependent,
              newRatePlanChargeId: dependentNewRatePlan,
            });
          }
        } else {
          dependentsWithChange.push(dep);
        }
      });
      if (!isDependentAlreadyAdded) {
        dependentsWithChange.push({
          ...dependent,
          newRatePlanChargeId: dependentNewRatePlan,
        });
      }
      setDependentChanges(dependentsWithChange);
    }
  };

  const previewTierSubmitDependents = () => {
    const testBody: unknown[] = [];
    const primaryBody = {
      payrollStatus: payrollStatus,
      personId: member?.personId,
      programCode,
      orderActions: [
        user.isPayroll
          ? {
              currentRatePlanChargeId: currentRatePlanChargeId,
              newRatePlanChargeId:
                change !== currentRatePlanChargeId
                  ? change
                  : currentRatePlanChargeId,
              type:
                change !== currentRatePlanChargeId
                  ? "SubscriptionUpdate"
                  : "NoAction",
            }
          : {
              currentRatePlanChargeId: currentRatePlanChargeId,
              newRatePlanChargeId:
                change !== currentRatePlanChargeId
                  ? change
                  : currentRatePlanChargeId,
              currentSubscriptionNumber:
                member?.eligibilities[0].currentSubscription
                  ?.subscriptionNumber,
              type:
                change !== currentRatePlanChargeId
                  ? "SubscriptionUpdate"
                  : "NoAction",
            },
      ],
    };
    if (change !== currentRatePlanChargeId) testBody.push(primaryBody);

    dependentChanges.map((dependent) => {
      return testBody.push({
        payrollStatus: payrollStatus,
        personId: dependent.personId,
        programCode,
        orderActions: [
          user.isPayroll
            ? {
                currentRatePlanChargeId:
                  dependent.eligibilities[0].currentSubscription
                    ?.ratePlanChargeId,
                newRatePlanChargeId: dependent.newRatePlanChargeId, // Use the newRatePlanChargeId variable
                type: "SubscriptionUpdate",
              }
            : {
                currentRatePlanChargeId:
                  dependent.eligibilities[0]?.currentSubscription
                    ?.ratePlanChargeId,
                newRatePlanChargeId: dependent.newRatePlanChargeId, // Use the newRatePlanChargeId variable
                currentSubscriptionNumber:
                  dependent?.eligibilities[0]?.currentSubscription
                    ?.subscriptionNumber,
                type: "SubscriptionUpdate",
              },
        ],
        promoCodes: finalPromo
          ? finalPromo.includes(",")
            ? finalPromo.split(",").map((code) => code.trim())
            : [finalPromo]
          : [],
      });
    });
    if (testBody.length === 0) return;

    setLoadingPreview(true);
    setErrorSummary(false);
    axiosInstance
      .post(
        "/account/subscriptions/preview",
        {
          members: testBody,
          promoCodes: finalPromo
            ? finalPromo.includes(",")
              ? finalPromo.split(",").map((code) => code.trim())
              : [finalPromo]
            : [],
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          const { data } = response;
          setPreviewData(data);
          setPromoCodeError(data);
          if (data.promoCodeError.length !== 0) {
            setValidPromo("FALSE");
          }
          if (
            data.immediatePaymentSummary.immediatePaymentDetails[0]?.chargeName
          ) {
            setIsUpgrade(true);
          } else {
            setIsUpgrade(false);
          }
          // PENDING

          setIsChange(true);
          setShouldRevertChildOptions(false);
          setLoadingPreview(false);
          setIsFailed(false);
          setUpdateUnderageDependent(false);
        } else {
          setUpdateUnderageDependent(false);

          setIsFailed(true);
        }
      })
      .catch(() => {
        setLoadingPreview(false);
        setErrorSummary(true);
        setUpdateUnderageDependent(false);
      });
  };

  const handleAddDepEmailChange = (value: string, addDep: AddDependent, i) => {
    if (isValidEmail(value)) {
      checkEmailExistance(value, i);
    }
    const updatedAddingDeps = addingDependents.map((item, index) => {
      if (index === i) {
        item.person.email = value;
      }
      return item;
    });

    setAddingDependents(updatedAddingDeps);
  };

  const handleAddDepPlanChange = (value: string, addDep: AddDependent, i) => {
    const updatedAddingDeps = addingDependents.map((item, index) => {
      if (index === i) {
        item.subscription.ratePlanChargeID = value;
        item.subscription.programName = productRatePlans.find(
          (plan) => plan.recurringRatePlanChargeId === value
        )?.name;
      }
      return item;
    });
    setAddingDependents(updatedAddingDeps);
  };

  const handlePaymentChange = (newPaymentMethod: Payment) => {
    setDefaultPaymentMethod(newPaymentMethod);
  };

  useEffect(() => {
    (async () => {
      if (showNonPayrollCheckoutModal) {
        setLoadingPayments(true);
        setLoadingPayments(false);
      }
    })();
  }, [showNonPayrollCheckoutModal]);

  useEffect(() => {
    const fetchData = async () => {
      await axiosInstance
        .get(`/account/next-payment-info`, {})
        .then((response) => {
          setnextPaymentDate(response.data.nextPaymentDate);
        })
        .catch((e) => console.log(e));
    };
    fetchData();
  }, []);

  const handleCardPrint = () => {
    const image = new Image();
    image.src = `data:image/png;base64,${memberCardBaseImage}`;
    const w = window.open("");
    w?.document.write(image.outerHTML);
  };
  const handleDependentCardDownload = (person: AccountSummaryMember) => {
    const savedDependentCards = dependentCards;
    let cardData = savedDependentCards.find(
      (dep) => dep.personId === person.personId
    );
    if (cardData === undefined) {
      axiosBaseInstance
        .get(`/clients/${config["client"]}/members/${person.personId}/card`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          if (data) {
            savedDependentCards.push({
              personId: person.personId,
              cardbase64: data,
            });
            setDependentCards(savedDependentCards);
            cardData = { personId: person.personId, cardbase64: data };
            const a = document.createElement("a");
            a.href = "data:image/png;base64," + cardData.cardbase64;
            a.download = `membercard_${person.email}.png`;
            a.click();
          }
        })
        .catch((e) => {
          console.log(e.toJSON());
        });
    } else {
      const a = document.createElement("a");
      a.href = "data:image/png;base64," + cardData.cardbase64;
      a.download = `membercard_${person.email}.png`;
      a.click();
    }
  };
  const handleDependentCardPrint = (person: AccountSummaryMember) => {
    const savedDependentCards = dependentCards;
    let cardData = savedDependentCards.find(
      (dep) => dep.personId === person.personId
    );
    if (cardData === undefined) {
      axiosBaseInstance
        .get(`/clients/${config["client"]}/members/${person.personId}/card`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          if (data) {
            savedDependentCards.push({
              personId: person.personId,
              cardbase64: data,
            });
            setDependentCards(savedDependentCards);
            cardData = { personId: person.personId, cardbase64: data };
            const imageDependent = new Image();
            imageDependent.src = `data:image/png;base64,${cardData.cardbase64}`;
            const wDependent = window.open("");
            wDependent?.document.write(imageDependent.outerHTML);
          }
        })
        .catch((e) => {
          console.log(e.toJSON());
        });
    } else {
      const imageDependent = new Image();
      imageDependent.src = `data:image/png;base64,${cardData.cardbase64}`;
      const wDependent = window.open("");
      wDependent?.document.write(imageDependent.outerHTML);
    }
  };

  const getCancellingTermDate = (
    member: AccountSummaryMember,
    nextPaymentDate: string | undefined
  ) => {
    const today = momentStartOfDay();
    if (user.isPayroll) {
      return today.toISOString();
    }

    const gymEligibility = member.eligibilities.find(
      (el) => el.productCode === "226"
    );
    if (!gymEligibility || !gymEligibility.initialProductCodeDate) {
      return nextPaymentDate;
    }

    const started = momentStartOfDay(gymEligibility.initialProductCodeDate);
    if (today.diff(started, "days") <= 30) {
      return today.toISOString();
    }

    return nextPaymentDate;
  };
  function validateCancelPlan(res) {
    if ((res.data.status as unknown as string) == "SUCCESS") {
      setIsDiscardingCancellation(false);
      setIsDiscardingPlanChange(false);
      refresh().then(() => {
        resetAccountAndManagePlan().then(() => {
          setIsCancelling(false);
          ProfileToast("Your account was successfully updated", true, client);
        });
      });
      return true;
    } else if ((res.data.status as unknown as string) == "FAILED") {
      setIsDiscardingCancellation(false);
      setIsDiscardingPlanChange(false);
      refresh().then(() => {
        resetAccountAndManagePlan().then(() => {
          setIsCancelling(false);
          setIsFailed(true);
        });
      });
      return false;
    } else if ((res.data.status as unknown as string) == "PROCESSING") {
      throw res;
    }
  }
  const cancelAccount = async (account: AccountSummaryMember) => {
    const body: AccountCancelRequest = {
      primaryPersonId: member?.personId,
      personId: account.personId,
    };

    if (eligibility.isPrimary && !user.isPayroll) {
      body.subscriptionNumber =
        eligibility.currentSubscription?.subscriptionNumber;
      body.zuoraAccountNumber = member?.subscriptionAccount?.accountNumber;
    }

    axiosInstance
      .post("/account/cancel", body)
      .then((response) => {
        poll(
          () =>
            axiosInstance
              .get(`/account/cancel/status/${response.data.executionId}`)
              .then(validateCancelPlan),
          45,
          3000
        );
      })
      .catch((e) => console.log(e));
  };

  const handleCheckout = () => {
    if (user.isPayroll) {
      setOpenPayrollModal(true);
    } else {
      setShowNonPayrollCheckoutModal(true);
    }
  };

  const defaultCardBody = (
    <Row>
      <Col className="manage-plans__member-name" xs={12} lg={5}>
        <h6 className="text-uppercase">Primary Member</h6>
        <div>
          <b>
            {member?.firstName} {member?.lastName}
          </b>
        </div>
        <span className="mt-minus-half-rem" style={{ display: "block" }}>
          <small>{member?.email}</small>
        </span>
      </Col>
      <Col xs={12} lg={7}>
        <h6 className="text-uppercase">Fitness Package</h6>
        <>
          {member.eligibilities[0].isMembershipActive === true ? (
            <Form.Group>
              <Form.Control
                className="package-dropdown"
                as="select"
                value={
                  futurePlanChargeId || change || currentRatePlanChargeId || ""
                }
                onChange={handlePlanChange}
                style={{
                  appearance: "auto",
                  maxWidth: "600px",
                }}
                disabled={
                  disablePlanSelect ||
                  isStudioOnly ||
                  loadingPreview ||
                  member.eligibilities[0].isPendingTermination === true ||
                  member.eligibilities[0].futureSubscription !== null ||
                  member.eligibilities[0].currentSubscriptionStatus ===
                    "ActiveFutureSuspend" ||
                  member.eligibilities[0].currentSubscriptionStatus ===
                    "Suspended"
                }
              >
                {member?.eligibilities[0].currentSubscriptionStatus !==
                "Active" ? (
                  <option
                    value={"termedDefault"}
                    key={"termedDefault"}
                    disabled
                  >
                    Please Select a Package
                  </option>
                ) : null}
                <BundlesDropDown
                  planId={futurePlanChargeId || currentRatePlanChargeId}
                  legacyMember={isLegacyMember}
                  filterOutStudio={!isStudioOnly}
                />
              </Form.Control>
            </Form.Group>
          ) : (
            <div>
              <FontAwesomeIcon icon={faInfoCircle} /> Account does not have an
              active fitness package
            </div>
          )}
        </>
      </Col>
      {!canChangePlan && <DiscardDisclaimer />}
      {dependent.length > 0 &&
        change !== member?.eligibilities[0].currentSubscription?.ratePlanName &&
        !member?.eligibilities[0].isPendingTermination && (
          <Alert className="primary-member-alert-info" variant="primary">
            <b>Heads up!</b> When changes are made to the primary member's
            fitness package, the changes take place for all family members added
            to your plan
          </Alert>
        )}
    </Row>
  );

  const MutualOfOmahaCardBody = (
    <Row>
      <Col className="manage-plans__member-name" xs={12} lg={5}>
        <h6 className="text-uppercase">Primary Member</h6>
        <div>
          <b>
            {member?.firstName} {member?.lastName}
          </b>
        </div>
        <span className="mt-minus-half-rem" style={{ display: "block" }}>
          <small>{member?.email}</small>
        </span>
      </Col>
      <Col xs={12} lg={7}>
        <h6 className="text-uppercase">Fitness Package</h6>
        <>
          {member.eligibilities[0].isMembershipActive === true ? (
            eligibility.isPendingTermination ? (
              <div className="no-active-fitness-package">
                <span>
                  Plan cancellation is in progress.{" "}
                  <u onClick={() => setIsDiscardingCancellation(true)}>
                    Discard your cancellation
                  </u>{" "}
                  to keep access to your plan.
                </span>
              </div>
            ) : (
              <Form.Group>
                <b>
                  {member.eligibilities[0].currentSubscription?.ratePlanName}
                </b>{" "}
                - ${member.eligibilities[0].currentSubscription?.price}/mo
              </Form.Group>
            )
          ) : (
            <div className="no-active-fitness-package">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <span>Account does not have an active fitness package</span>
            </div>
          )}
        </>
      </Col>
      {dependent.length > 0 &&
        change !== member?.eligibilities[0].currentSubscription?.ratePlanName &&
        !member?.eligibilities[0].isPendingTermination && (
          <Alert className="primary-member-alert-info" variant="primary">
            <b>Heads up!</b> When changes are made to the primary member's
            fitness package, the changes take place for all family members added
            to your plan
          </Alert>
        )}
    </Row>
  );
  const noPaymentMethodBody = (
    <Alert variant="info">
      No payment method found. In order to make changes to your account please
      add one <NavLink to="/account/billing">here</NavLink>.
    </Alert>
  );

  const dependentCanDiscardCancel = (item: AccountSummaryMember): boolean => {
    let currentEligibility = item.eligibilities[0];
    if (config["client"] === "hcsc") {
      const productEligibility = item.eligibilities.find(
        (e) => e.productCode === "226"
      );
      if (productEligibility) {
        currentEligibility = productEligibility;
      }
    }

    if (
      config["client"] === "hcsc" &&
      currentEligibility?.isPendingTermination &&
      moment(currentEligibility.memberTermDate).toDate() <= endOfDayDate
    ) {
      return true;
    } else if (
      currentEligibility.isPendingTermination &&
      !member?.eligibilities[0].isPendingTermination
    ) {
      return true;
    }

    return false;
  };

  const dependentCardFooter = (item, index) => (
    <Card.Text className="manage-plans__sub-text">
      {!entitlements && (
        <div className="mt-2">
          <h3>
            <i>No current plan</i>
          </h3>
        </div>
      )}
      {entitlements && entitlements.includes("memberCard") && (
        <div className="manage-plans__footer manage-plans__footer__btn">
          <div className="button-container">
            <Button
              variant="outline-primary"
              className="btn w-15 ml-2 btn-outline-manage-plan btn-sm download-card-button"
              onClick={() => handleDependentCardDownload(item)}
              disabled={item.eligibilities[0]?.isPendingTermination}
            >
              <FontAwesomeIcon icon={faDownload} /> Download Card
            </Button>
          </div>
          <div className="button-container">
            <Button
              variant="outline-primary"
              className="btn w-15 ml-2 btn-outline-manage-plan btn-sm print-card-button"
              onClick={() => handleDependentCardPrint(item)}
              disabled={item.eligibilities[0]?.isPendingTermination}
            >
              <FontAwesomeIcon icon={faPrint} /> Print Card
            </Button>
          </div>
          {dependentCanDiscardCancel(item) ? ( //this may need modified now
            <div
              className="manage-plans__discard"
              onClick={() => {
                setEmailCurFamMember(item.email);
                setIsDependent(true);
                toggleDiscardCancellationModalHandler();
              }}
              style={{
                marginLeft: "auto",
                paddingLeft: "100px",
                minHeight: "0px",
              }}
            >
              <img
                alt="arrow"
                className="manage-plans__discard__back-arrow"
                src={ArrowRotateLeftSolid}
                style={{
                  padding: "0",
                  width: "15px",
                }}
              />
              <p style={{ fontSize: "14px" }}>
                <b>Discard Changes</b>
              </p>
            </div>
          ) : !member?.eligibilities[0].isPendingTermination ? (
            item.eligibilities[0]?.futureSubscription !== null &&
            member?.eligibilities[0].futureSubscription === null ? (
              <span
                className={`manage-plans__cancel ${
                  isChange || item.eligibilities[0]?.isPendingTermination
                    ? "disabled-links"
                    : ""
                }`}
                onClick={() => {
                  setIsDiscardingUnderagePlanChange(index);
                }}
                style={{
                  marginLeft: "auto",
                  cursor: `pointer`,
                }}
              >
                <b style={{ color: `red`, fontSize: "14px" }}>
                  Discard Changes
                </b>
              </span>
            ) : member?.eligibilities[0].futureSubscription === null ? (
              <span
                className={`manage-plans__cancel ${
                  isChange || item.eligibilities[0]?.isPendingTermination
                    ? "disabled-links"
                    : ""
                }`}
                onClick={() => {
                  toggleRemoveModalHandler();
                  setRemoveFamilyDate(
                    getCancellingTermDate(item, nextPaymentDate)
                  );
                  handleCurrentFamMember(item.email);
                  setIsDependent(true);
                }}
                style={{
                  marginLeft: "auto",
                  cursor: `pointer`,
                }}
              >
                <b style={{ color: `red`, fontSize: "14px" }}>
                  X Remove Member
                </b>
              </span>
            ) : null
          ) : null}
        </div>
      )}
    </Card.Text>
  );

  const primaryCardFooter = () => (
    <>
      <Card.Text className="manage-plans__sub-text">
        {!entitlements && (
          <div className="mt-2">
            <h3>
              <i>No current plan</i>
            </h3>
          </div>
        )}
        {entitlements &&
          entitlements.includes("memberCard") &&
          member.eligibilities[0].isMembershipActive === true && (
            <div className="manage-plans__footer manage-plans__footer__btn">
              {member.eligibilities[0].currentSubscription?.tier !== "0" &&
                !isStudioOnly && (
                  <>
                    <div className="button-container">
                      <a
                        download="membership_card.png"
                        className={`btn btn-outline-manage-plan btn-sm download-card-button ${
                          member?.eligibilities[0].isPendingTermination
                            ? "disabled-links"
                            : ""
                        }`}
                        href={`data:image/png;base64,${memberCardBaseImage}`}
                      >
                        <FontAwesomeIcon icon={faDownload} /> Download Card
                      </a>
                    </div>
                    <div className="button-container">
                      <Button
                        variant="outline-primary"
                        className="btn w-15 ml-2 btn-outline-manage-plan btn-sm print-card-button"
                        onClick={handleCardPrint}
                        disabled={member?.eligibilities[0].isPendingTermination}
                      >
                        <FontAwesomeIcon icon={faPrint} /> Print Card
                      </Button>
                    </div>{" "}
                  </>
                )}
              {isStudioOnly && (
                <div>
                  <Button
                    as={Link}
                    to="/enroll/package"
                    variant="primary"
                    className="btn w-15 ml-2 btn-primary btn-sm print-card-button"
                  >
                    Upgrade My Fitness Package
                  </Button>
                </div>
              )}

              {memberCanCancel &&
              !member?.eligibilities[0].isPendingTermination &&
              canChangePlan ? (
                <span
                  className={`manage-plans__cancel`}
                  onClick={() => setIsCancelling(true)}
                  style={{
                    marginLeft: "auto",
                    cursor: `pointer`,
                  }}
                >
                  <b style={{ color: `red`, fontSize: "14px" }}>
                    X Cancel My Plan
                  </b>
                </span>
              ) : !canChangePlan &&
                !termReasonPattern.test(termReason) &&
                showDiscardCancel ? (
                <div
                  className="manage-plans__discard"
                  onClick={() =>
                    eligibility.isPendingTermination
                      ? setIsDiscardingCancellation(true)
                      : setIsDiscardingPlanChange(true)
                  }
                  style={{
                    marginLeft: "auto",
                    minHeight: "0px",
                    cursor: `pointer`,
                  }}
                >
                  <img
                    alt="arrow"
                    className="manage-plans__discard__back-arrow"
                    src={ArrowRotateLeftSolid}
                    style={{
                      padding: "0",

                      width: "15px",
                    }}
                  />
                  <p style={{ fontSize: "14px" }}>
                    <b>
                      {member?.eligibilities[0].isPendingTermination
                        ? `Discard Cancellation`
                        : `Discard Changes`}
                    </b>
                  </p>
                </div>
              ) : null}
            </div>
          )}
        {!member.eligibilities[0].isMembershipActive ? (
          <div className="manage-plans__footer manage-plans__footer__btn">
            <div>
              <Button
                as={Link}
                to="/enroll/package"
                variant="primary"
                className="btn w-15 ml-2 btn-primary btn-sm print-card-button"
              >
                Upgrade My Fitness Package
              </Button>
            </div>
          </div>
        ) : null}
      </Card.Text>
    </>
  );

  const addDependentCardBody = (item: AddDependent, idx) => (
    <Row key={item.person.firstName + item.person.lastName}>
      <Col className="manage-plans__member-name" xs={12} lg={5}>
        <h6 className="text-uppercase">Family Member</h6>
        <div>
          <b>
            {item.person.firstName} {item.person.lastName}
          </b>
        </div>
      </Col>
      <Col xs={12} lg={7}>
        <h6 className="text-uppercase">
          Email Address <span style={{ color: "red" }}>*</span>
        </h6>

        <InputGroup>
          <FormControl
            type="text"
            value={item.person.email}
            disabled={item.person.personId !== 0}
            placeholder="user@example.com"
            onChange={(e) => {
              handleAddDepEmailChange(e.target.value, item, idx);
            }}
          ></FormControl>
          {item.person.isEmailTaken && (
            <text className="invalid-email-text">
              An account already exists with that email. Please enter a
              different email address.
            </text>
          )}
        </InputGroup>
      </Col>
    </Row>
  );

  const addDependentCardFooter = (item: AddDependent, idx) => {
    return (
      <Card.Text className="manage-plans__sub-text manage-plans__footer">
        <Container>
          <Row>Fitness Package</Row>
          <Row>
            <Col xs="7">
              <Form.Group>
                <Form.Control
                  className="package-dropdown"
                  as="select"
                  value={addingDependents[idx].subscription.ratePlanChargeID}
                  onChange={(e) => {
                    handleAddDepPlanChange(e.target.value, item, idx);
                  }}
                  disabled={loadingPreview}
                  style={{
                    appearance: "auto",
                    maxWidth: "600px",
                  }}
                >
                  {/* 
          Having the Rate plans pre loaded was the only way I could get the dom to properly render the select with the users package as default, this results in fewer calls anyways
        */}
                  <NoOpBundlesDropDown
                    error={error}
                    loading={loading}
                    productRatePlans={filterProductRateplans(
                      productRatePlans,
                      item,
                      change
                    )}
                    addendum={addendum}
                    planId={
                      isLegacyMember
                        ? productRatePlans[0].recurringRatePlanChargeId
                        : futurePlanChargeId || currentRatePlanChargeId
                    }
                  />
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs="5">
              <a
                className="manage-plans__remove-add"
                onClick={() => {
                  onRemoveAddMember(idx);
                }}
              >
                X Remove Member
              </a>
            </Col>
          </Row>
        </Container>
      </Card.Text>
    );
  };

  const addDependentList = addingDependents?.map((addDep, index) => {
    return (
      <Card
        className={
          "manage-plans__card" +
          (addDep.person.isUnderage ? " underage-dependent__card" : "")
        }
        key={addDep.person.firstName + index}
      >
        <Card.Body>
          <div className="custom-card-content">
            {addDependentCardBody(addDep, index)}
            {!isSmallScreen ? addDependentCardFooter(addDep, index) : ""}
          </div>
        </Card.Body>
        {isSmallScreen ? addDependentCardFooter(addDep, index) : ""}
      </Card>
    );
  });

  const isUnderageDependent = (birthDate: string) => {
    return calculateAge(birthDate) <= clientConfig.minBuyerMemberAge!;
  };

  const dependentList = filteredDependents?.map((item, index) => {
    return (
      <Card
        className={
          "manage-plans__card" +
          (isUnderageDependent(item.dateOfBirth)
            ? " underage-dependent__card"
            : "")
        }
        key={item.email}
      >
        <Card.Body>
          <div className="custom-card-content">
            {hasPaymentMethods ? (
              <DependentCardBody
                dependent={item}
                handleDependentPlanChange={handleDependentPlanChange}
                setSelectedMember={handleSelectingMember}
                highestSelectableTier={
                  isLegacyMember
                    ? "2"
                    : isUnderageDependent(item.dateOfBirth)
                    ? getProductTierFromRatePlanChargeId(change)
                    : (member?.eligibilities[0]?.currentSubscription
                        ?.tier as unknown as string) // Add null check for 'member' object
                }
                shouldRevertToDefault={shouldRevertChildOptions}
                setDependentToPrimarySelection={
                  isUnderageDependent(item.dateOfBirth)
                    ? updateUnderageDependent
                    : false
                }
                parentRatePlanChargeId={change}
                disable={loadingPreview}
                triggerPreviewPrimary={() => {
                  if (dependentChanges.length !== 0) {
                    previewTierSubmitDependents();
                  } else {
                    previewTierSubmit(change);
                  }
                }}
                getProductTierFromRatePlanChargeId={(ratePlan) =>
                  getProductTierFromRatePlanChargeId(ratePlan)
                }
              />
            ) : (
              noPaymentMethodBody
            )}
          </div>
        </Card.Body>
        {dependentCardFooter(item, index)}
      </Card>
    );
  });

  useEffect(() => {
    if (proccessingOrder && intervalId) {
      if (progress >= 100) {
        setProgress(100);
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
    () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [progress, proccessingOrder]);

  return (
    <Fragment>
      <div
        className={`d-flex ${isMobileScreenSize ? "flex-column" : "flex-row"}`}
      >
        <div className={`d-flex flex-column`} style={{ flexBasis: "70%" }}>
          <InformationDisplay header="Manage Plan" />
          {nextPayment?.overdue ? (
            <OutStandingBalanceBanner
              amount={nextPayment?.amount}
              due={nextPayment?.due}
            />
          ) : null}
          {openAddModal ? (
            <AddFamilyMember
              open={openAddModal}
              onClose={onAddFamilyCloseWal}
              onAdd={onAdd}
              zipCode={member!.postalCode}
            />
          ) : null}
          {openAddHCSCModal ? (
            <AddFamilyMemberHCSC
              open={openAddHCSCModal}
              onClose={onAddFamilyClose}
              onAdd={onAdd}
              zipCode={member!.postalCode}
              isPrimaryStudioOnly={isStudioOnly}
            />
          ) : null}
          {openRemoveModal ? (
            <FamilyStatusModal
              show={openRemoveModal}
              status={
                openRemoveModal && isRemovingMember ? "LOADING" : "REMOVE"
              }
              firstName={firstNameCurFamMember}
              lastName={lastNameCurFamMember}
              onDone={onSuccess}
              progress={progress}
              close={() => {
                toggleRemoveModalHandler();
                setRemoveFamilyDate(undefined);
              }}
              date={removeFamilyDate}
            />
          ) : null}
          {openEditModal ? (
            <FamilyStatusModal
              show={openEditModal}
              status="EDIT"
              firstName={selectedMemberName}
              lastName={selectedMemberLastName}
              handleChangeEmail={handleEmailChange}
              close={toggleEditModalHandler}
              email={selectedMemberEmail}
              errorMessage={errorMessage}
              isDependentEmailLoading={isDependentEmailLoading}
            />
          ) : null}
          {isFailed && <AccountUpdateFailedBanner />}
          {isImpersonated && payrollStatus === USER_PAYROLL_STATUS.Payroll && (
            <div className="csr-tip">
              <small>
                <i>
                  <strong>CSR Tip!</strong> You cannot make changes to this
                  member's fitness package. Please refer this member to the
                  website to complete any package change requests that require
                  payroll authorization.
                </i>
              </small>
            </div>
          )}
          {/* <Card className="edit-plan"> */}
          <Card.Body>
            <div className={`d-flex flex-row`}>
              <div
                className="mb-4"
                style={{ width: isMobileScreenSize ? "100%" : "90%" }}
              >
                <Card className="manage-plans__card">
                  <Card.Body>
                    {hasPaymentMethods
                      ? config["client"] === "mutual-of-omaha"
                        ? MutualOfOmahaCardBody
                        : defaultCardBody
                      : noPaymentMethodBody}
                  </Card.Body>
                  {primaryCardFooter()}
                </Card>

                {showUnderageBanner ? (
                  <div style={{ width: "90%" }}>
                    <UnderageFamilyMemberBanner
                    // tierNumber={
                    //   member?.eligibilities[0].currentSubscription?.tier
                    //     ? +member?.eligibilities[0].currentSubscription?.tier
                    //     : -1
                    // }
                    ></UnderageFamilyMemberBanner>
                  </div>
                ) : null}
                {loadingAddDependents ? (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  addDependentList
                )}
                {loadingDependents ? (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  dependentList
                )}
              </div>
            </div>
            {clientConfig["isFamilyMembershipAllowed"] ? (
              <Button
                onClick={toggleModalHandler}
                className="manage-plans__button"
                disabled={
                  isChange ||
                  member.eligibilities[0].isMembershipActive === false ||
                  member.eligibilities[0].isPendingTermination === true ||
                  member.eligibilities[0].currentSubscriptionStatus ===
                    "ActiveFutureTerm"
                }
                style={{ width: isMobileScreenSize ? "100%" : "90%" }}
              >
                <FontAwesomeIcon icon={faPlusCircle} />
                Add Family Member
              </Button>
            ) : null}
            {isCancelling && member && (
              <CancelSubscriptionModal
                onClose={() => setIsCancelling(false)}
                onCancel={() => cancelAccount(member)}
                termDate={getCancellingTermDate(member, nextPayment?.due)}
                hasUnderageDependents={hasUnderageDependents}
                isLoadingDependents={loadingDependents}
              />
            )}
            {isDiscardingPlanChange && (
              <DiscardPlanChangeModal
                nextPaymentDate={nextPaymentDate}
                onClose={async (success) => {
                  if (success !== undefined) {
                    await resetAccountAndManagePlan();
                  }

                  setIsDiscardingPlanChange(false);

                  if (success !== undefined) {
                    setIsFailed(!success);
                  }
                }}
                hasUnderageDependents={hasUnderageDependents}
                isLoadingDependents={loadingDependents}
              />
            )}
            {isDiscardingUnderagePlanChange !== null && (
              <DiscardUnderagePlanChangeModal
                dependent={filteredDependents[isDiscardingUnderagePlanChange]}
                nextPaymentDate={nextPaymentDate}
                onClose={async (success) => {
                  if (success !== undefined) {
                    await resetAccountAndManagePlan();
                  }

                  setIsDiscardingUnderagePlanChange(null);

                  if (success !== undefined) {
                    setIsFailed(!success);
                  }
                }}
                hasUnderageDependents={hasUnderageDependents}
                isLoadingDependents={loadingDependents}
              />
            )}
            {openDiscardCancellationModal && (
              <DiscardCancellationModal
                member={
                  isDependent
                    ? findCurrentFamMember(emailCurFamMember)!
                    : member
                }
                onClose={async (success) => {
                  loadDependents();
                  setIsDependent(false);
                  setIsDiscardingCancellation(false);
                  if (success !== undefined) {
                    setIsFailed(!success);
                  }
                }}
                hasUnderageDependents={hasUnderageDependents}
                isLoadingDependents={loadingDependents}
              />
            )}
            {showNonPayrollCheckoutModal && (
              <NonPayrollCheckoutModal
                show={showNonPayrollCheckoutModal}
                onClose={() => setShowNonPayrollCheckoutModal(false)}
                paymentMethod={defaultpaymentMethod}
                paymentMethods={paymentMethods}
                onPaymentChange={handlePaymentChange}
                shippingAddress={{
                  street1: defaultAddress?.address1 ?? "",
                  street2: defaultAddress?.address2 ?? "",
                  city: defaultAddress?.city ?? "",
                  state: defaultAddress?.state ?? "",
                  zip: defaultAddress?.postalCode ?? "",
                }}
                total={
                  previewData.immediatePaymentSummary
                    ?.immediateTotalPaymentAfterDiscountAmountWithTax
                }
                onAddressEdit={(values) => setAddress(values)}
                onConfirm={(paymentId) => handleConfirmCheckout(paymentId)}
                setAddressChanged={setAddressChanged}
              />
            )}
          </Card.Body>
        </div>
        <div className="d-flex flex-column flex-1">
          <Col xs={12} md="auto" className="edit-plan-column">
            <PlanSummaryUpdate
              currentPlanTotal={convertToCurrency(
                member?.eligibilities[0].currentSubscription?.price
                  ? member?.eligibilities[0].currentSubscription?.price
                  : 0
              )}
              previewData={previewData}
              userTermed={
                member?.eligibilities[0].currentSubscriptionStatus ===
                  "Active" ||
                member?.eligibilities[0].currentSubscriptionStatus ===
                  "ActiveFutureTerm"
                  ? false
                  : true
              }
              loadingPending={loadingPreview}
              promoLoading={promoLoading}
              couponLoading={couponLoading}
              onPromoChange={handlePromo}
              onPromoRemove={handlePromoRemove}
              onPromoSubmit={handlePromoSubmit}
              onPreviewPromoSubmit={handlePreviewPromoSubmit}
              onCancelPromo={onCancelPromoCode}
              onApplyPromo={onApplyPromoCode}
              showPromoModal={showPromoModal}
              onClosePromoModal={togglePromoModal}
              errorSummary={errorSummary}
              validPromo={validPromo}
              promoOpen={promoOpen}
              promo={promo}
              currentPromo={currentPromo}
              isUpgrade={isUpgrade}
              isChange={isChange}
            />
          </Col>
        </div>
      </div>
      {/* </Card> */}
      {openPayrollModal && (
        <div
          onClick={() => setOpenPayrollModal(false)}
          style={{
            position: `absolute`,
            top: `0%`,
            right: `0`,
            left: `0`,
            zIndex: `98 !important`,
            background: `black`,
            opacity: `0.5`,
            width: `100%`,
            height: `100%`,
            paddingLeft: `6%`,
            paddingTop: `20px`,
            paddingBottom: `20px`,
            boxShadow: `0px 2px 8px #dddddd`,
            border: "1px solid rgba(0,0,0,.122)",
            borderRadius: 15,
          }}
        ></div>
      )}
      <div
        style={{
          display: openPayrollModal ? `` : `none`,
          transition: `all 10s ease`,
          transform: !openPayrollModal ? `translateY(-100%)` : `translateY(0%)`,
          position: `absolute`,
          top: `30%`,
          right: `0`,
          left: `0`,
          zIndex: `99 !important`,
          background: `white`,
          width: `100%`,
          paddingLeft: `6%`,
          paddingTop: `20px`,
          paddingBottom: `20px`,
          boxShadow: `0px 2px 8px #dddddd`,
          border: "1px solid rgba(0,0,0,.122)",
          borderRadius: 15,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <PayrollDeductionModal
            open={openPayrollModal}
            member={member ? member : null}
            hasRendered={true}
            onCancel={() => setOpenPayrollModal(false)}
            onConfirm={() => handleConfirmChangeTierPayroll()}
            checkoutDisabled={(b) => setCheckoutDisabled(b)}
          />
          <Col xs={12} md="auto" style={{ padding: 0, float: "right" }}>
            <PlanSummaryUpdate
              currentPlanTotal={convertToCurrency(
                member?.eligibilities[0].currentSubscription?.price
                  ? member?.eligibilities[0].currentSubscription?.price
                  : 0
              )}
              previewData={previewData}
              userTermed={
                member?.eligibilities[0].currentSubscriptionStatus ===
                  "Active" ||
                member?.eligibilities[0].currentSubscriptionStatus ===
                  "ActiveFutureTerm"
                  ? false
                  : true
              }
              loadingPending={loadingPreview}
              couponLoading={couponLoading}
              promoLoading={promoLoading}
              showPromoModal={showPromoModal}
              onClosePromoModal={togglePromoModal}
              onPromoChange={handlePromo}
              onCancelPromo={onCancelPromoCode}
              onPromoRemove={handlePromoRemove}
              onPromoSubmit={handlePromoSubmit}
              onApplyPromo={onApplyPromoCode}
              errorSummary={errorSummary}
              validPromo={validPromo}
              promoOpen={promoOpen}
              promo={promo}
              currentPromo={currentPromo}
              isUpgrade={isUpgrade}
              isChange={isChange}
            />
          </Col>
        </div>
        <div
          style={{
            display: `flex`,
            justifyContent: `right`,
          }}
        >
          <Button
            variant="outline-primary"
            className="nav-btn-enroll font-weight-bold"
            style={{ whiteSpace: "nowrap", marginRight: `15px` }}
            onClick={() => setOpenPayrollModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="nav-btn-enroll font-weight-bold"
            style={{ whiteSpace: "nowrap", marginRight: `30px` }}
            disabled={checkoutDisabled}
            onClick={() => handleConfirmChangeTierPayroll()}
          >
            Complete Checkout
          </Button>
        </div>
      </div>
      {/* )} */}
      <ProcessingOrderModal
        show={proccessingOrder}
        progress={progress}
        onClose={() => {
          return;
        }}
      />
      {isChange && !openPayrollModal && (
        <FooterCard>
          <Container>
            <Row>
              <Col md={6} style={{ marginLeft: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "flex-end",
                    columnGap: "1rem",
                  }}
                >
                  <Button
                    variant="outline-primary"
                    className="nav-btn-enroll font-weight-bold"
                    style={{ whiteSpace: "nowrap", width: "100%" }}
                    onClick={handleCancelChangeTier}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="nav-btn-enroll font-weight-bold"
                    style={{ whiteSpace: "nowrap", width: "100%" }}
                    onClick={handleCheckout}
                    disabled={disableCheckout || summaryError || errorSummary}
                  >
                    Confirm & Checkout
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </FooterCard>
      )}
    </Fragment>
  );
};
export default ManagePlanUpdate;
