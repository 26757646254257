import { Tile } from "./Tile";
import ModalMapHCSC from "../../../resources/LuxuryModalMapHCSC.svg";
import LuxuryGymPin from "../../../resources/LuxuryGymPin.svg";
import LeftArrowRotate from "../../../resources/ArrowRotateLeftSolidBlue.svg";
import { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { AccountContext } from "../../../context/AccountProvider";
import axiosInstance, { axiosNetworkInstance } from "../../../axios.instance";
import { GymLocationResponse } from "../../../utility/member-portal-api-types";
import {
  faInfoCircle,
  faExternalLinkAlt,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Popover,
  OverlayTrigger,
  Spinner,
  Modal,
  Button,
} from "react-bootstrap";
import { differenceInDays } from "date-fns";
import ProfileToast from "../../../utility/ProfileToast";
import { useConfig } from "../../../configuration/useConfig";
import ChooseLuxuryGymModal from "./ChooseLuxuryGymModal";

const SomethingWentWrongModal = ({
  onCancel,
  show,
}: {
  show: boolean;
  onCancel: () => void;
}) => {
  return (
    <Modal show={show}>
      <Modal.Header
        placeholder=""
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
        className="modal-header-form"
      >
        <div className="modal-header-form__exclamation"></div>
        <h2>
          <b>Something Went Wrong</b>
        </h2>
      </Modal.Header>
      <Modal.Body className="modal-body-form">
        <p style={{ textAlign: "left" }}>
          It seems we're having trouble processing your request. Please try
          again later.
        </p>
      </Modal.Body>
      <Modal.Footer className="modal-footer-form">
        <Button
          variant="outline-primary"
          className="nav-btn-enroll font-weight-bold"
          style={{ whiteSpace: "nowrap", width: `65%`, height: `50%` }}
          onClick={onCancel}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

type DiscardLuxuryGymProps = {
  show: boolean;
  loading: boolean;
  onHide: () => void;
  handleSubmit: () => void;
  gymInfo: {
    effectiveDate: string;
    newGymName: string;
    currentGymName: string;
  };
};
const DiscardLuxuryGymModal = ({
  show,
  onHide,
  handleSubmit,
  loading,
  gymInfo,
}: DiscardLuxuryGymProps) => {
  return (
    <Modal show={show} centered>
      <Modal.Header
        placeholder=""
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <Modal.Title>
          <h2>Discard Luxury Gym Change?</h2>
          <span style={{ cursor: "pointer" }} onClick={onHide}>
            X
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Our records indicate you changed your luxury gym to{" "}
        <span style={{ fontWeight: "bold" }}>{gymInfo.newGymName}</span>, which
        will take effect on{" "}
        <span style={{ fontWeight: "bold" }}>{gymInfo.effectiveDate}</span>.
        Would you like to discard this change? If you discard, your luxury gym
        will remain as{" "}
        <span style={{ fontWeight: "bold" }}>{gymInfo.currentGymName}</span>{" "}
        until further changes are made.
      </Modal.Body>
      <Modal.Footer>
        <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
          <button
            onClick={onHide}
            disabled={loading}
            className="col btn btn-outline-primary ml-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="col mx-2 btn btn-secondary "
          >
            {loading ? "Loading..." : "Discard Change"}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const GymPopover = (
  street?: string,
  city?: string,
  state?: string,
  zip?: string
) => {
  const toLink = `https://maps.google.com/maps?q=${street}+${city}+${state}+${zip}`;
  return (
    <Popover id="popover-positioned-top" title="Popover top">
      <Popover.Content>
        {street}, <br />
        {city}, {state} {zip} <br />
        <a
          className="luxury-gym__direction-link"
          href={toLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Get directions
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            className="luxury-gym__link-icon"
          />
        </a>
      </Popover.Content>
    </Popover>
  );
};

const LuxuryGymTile = () => {
  const { member, refresh } = useContext(AccountContext);

  // Filtering out the gyms that past its end date
  const numberOfGyms = useMemo(() => {
    const memberLuxuryGyms = member?.eligibilities[0].luxuryGyms?.filter(
      (gym) => differenceInDays(new Date(gym.endDate), new Date()) >= 0
    );
    return memberLuxuryGyms ? memberLuxuryGyms?.length : 0;
  }, [member]);

  const luxuryGyms = useMemo(() => {
    const memberLuxuryGyms = member?.eligibilities[0].luxuryGyms?.filter(
      (gym) => differenceInDays(new Date(gym.endDate), new Date()) >= 0
    );
    return memberLuxuryGyms;
  }, [member]);

  const [gym, setGym] = useState<GymLocationResponse>();
  const [isSpinning, setIsSpinning] = useState<boolean>(true);
  const [changeinProgress, setChangeinProgress] = useState<boolean>(false);
  const [hasSelectedGym, setHasSelectedGym] = useState<boolean>(false);
  const [gymList, setGymList] = useState<GymLocationResponse[]>([]);
  const [loadingDiscardLuxuryGym, setLoadingDiscardLuxuryGym] = useState(false);
  const { client } = useConfig();
  const [showSomethingWentWrongModal, setShowSomethingWentWrongModal] =
    useState(false);
  const [showDiscardLuxuryGymModal, setShowDiscardLuxuryGymModal] =
    useState(false);
  const [openChooseLuxuryGymModal, setOpenChooseLuxuryGymModal] =
    useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  useEffect(() => {
    if (showSuccessToast) {
      setTimeout(() => {
        ProfileToast("Your account was successfully updated", true, client);
      }, 1000);
    }
  }, [showSuccessToast]);

  const eightTeenYearsAgo = () => {
    const date = new Date(new Date().setHours(0, 0, 0, 0));

    date.setFullYear(date.getFullYear() - 18);

    return date.getTime();
  };

  const isUnderEighteen = (birthday?: string) => {
    if (birthday) return new Date(birthday).getTime() >= eightTeenYearsAgo();

    return false;
  };

  const toggleSomethingWentWrongModal = () =>
    setShowSomethingWentWrongModal((prev) => !prev);
  const toggleDiscardLuxuryGymModal = () =>
    setShowDiscardLuxuryGymModal((prev) => !prev);

  const discardLuxuryGymChange = async (luxuryGymCode: string) => {
    try {
      setLoadingDiscardLuxuryGym(true);
      const response = await axiosInstance(
        `/account/luxury-gym/${luxuryGymCode}`,
        { method: "PUT" }
      );

      if (response.status !== 200) {
        toggleSomethingWentWrongModal();
        toggleDiscardLuxuryGymModal();
        return;
      }

      await refresh();
      ProfileToast("Your account was successfully updated", true, client);
      toggleDiscardLuxuryGymModal();
    } catch (err) {
      toggleSomethingWentWrongModal();
    } finally {
      setLoadingDiscardLuxuryGym(false);
    }
  };
  const handleDiscardLuxuryGym = () => {
    toggleDiscardLuxuryGymModal();
  };

  const fillGymList = async () => {
    setHasSelectedGym(true);
    const gyms = luxuryGyms;
    gyms?.sort((a, b) =>
      a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0
    );
    setChangeinProgress(true);
    const tempList = new Array<GymLocationResponse>(numberOfGyms);
    let waitingOn = numberOfGyms;

    await new Promise<void>((resolve, reject) => {
      gyms?.map(function (val, idx) {
        axiosNetworkInstance
          .get(`/locations/${val.code}`, {})
          .then((response) => {
            waitingOn--;
            if (response.data) {
              tempList[idx] = response.data.data;
            }
            if (waitingOn <= 0) {
              setIsSpinning(false);
              setGymList(tempList);
              resolve();
            }
          })
          .catch((error) => {
            console.log(error.toJSON());
            waitingOn--;
            if (waitingOn <= 0) {
              setIsSpinning(false);
              setGymList(tempList);
              reject();
            }
          });
      });
    });
  };

  const fillGym = async (code: string) => {
    try {
      setHasSelectedGym(true);
      setChangeinProgress(false);
      const response = await axiosNetworkInstance.get(`/locations/${code}`, {});

      setIsSpinning(false);
      if (response.data) {
        setGym(response.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSpinning(false);
    }
  };

  const initGymList = useCallback(async () => {
    const code = luxuryGyms ? luxuryGyms[0]?.code : null;
    if (numberOfGyms == 1 && code) {
      await fillGym(code);
    } else if (numberOfGyms && numberOfGyms > 1) {
      await fillGymList();
    } else {
      setIsSpinning(false);
    }
  }, [luxuryGyms, numberOfGyms]);

  useEffect(() => {
    initGymList();
  }, [numberOfGyms]);

  const getStartDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  };

  const getContent = () => {
    if (isSpinning) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center luxury-gym__content">
          <Spinner animation="border"></Spinner>
        </div>
      );
    }
    if (hasSelectedGym) {
      if (!changeinProgress) {
        return (
          <div className="d-flex flex-column justify-content-center align-items-center luxury-gym__content">
            <span className="luxury-gym__bug">
              <span className="luxury-gym__bug__text">
                <b>CURRENT LUXURY GYM</b>
              </span>
            </span>
            <b className="luxury-gym__gym-name">{gym?.name}</b>
            <OverlayTrigger
              trigger="click"
              placement="top"
              rootClose
              overlay={GymPopover(gym?.street, gym?.city, gym?.state, gym?.zip)}
            >
              <span className="luxury-gym__gym-details">View Gym Details</span>
            </OverlayTrigger>
          </div>
        );
      }
      if (changeinProgress) {
        return (
          <div className="d-flex flex-column justify-content-center align-items-center luxury-gym__content">
            <span className="luxury-gym__bug">
              <span className="luxury-gym__bug__text">
                <b>CURRENT LUXURY GYM</b>
              </span>
            </span>
            <b className="luxury-gym__gym-name">{gymList[0]?.name}</b>
            <OverlayTrigger
              trigger="click"
              placement="top"
              rootClose
              overlay={GymPopover(
                gymList[numberOfGyms - 2]?.street,
                gymList[numberOfGyms - 2]?.city,
                gymList[numberOfGyms - 2]?.state,
                gymList[numberOfGyms - 2]?.zip
              )}
            >
              <span className="luxury-gym__gym-details">View Gym Details</span>
            </OverlayTrigger>
            <FontAwesomeIcon
              icon={faArrowDown}
              className="luxury-gym__next-arrow"
              color="#82B265"
              size="lg"
            />
            <span className="luxury-gym__bug luxury-gym__next">
              <span className="luxury-gym__bug__text">
                <b>Next</b> (Starts{" "}
                {getStartDate(
                  luxuryGyms ? luxuryGyms[numberOfGyms - 1]?.startDate : "ERR"
                )}
                )
              </span>
            </span>
            <b className="luxury-gym__gym-name">{gymList[1]?.name}</b>
            <OverlayTrigger
              trigger="click"
              placement="top"
              rootClose
              overlay={GymPopover(
                gymList[numberOfGyms - 1]?.street,
                gymList[numberOfGyms - 1]?.city,
                gymList[numberOfGyms - 1]?.state,
                gymList[numberOfGyms - 1]?.zip
              )}
            >
              <span className="luxury-gym__gym-details">View Gym Details</span>
            </OverlayTrigger>
          </div>
        );
      }
    }
    return (
      <div className="d-flex flex-column justify-content-center align-items-center luxury-gym__content">
        <img
          src={ModalMapHCSC}
          height="106"
          width="227"
          alt="US Map with Location markers"
        />
        <h3 style={{ paddingTop: "1rem" }}>
          <b>No Luxury Gym Selected</b>
        </h3>
        {!member?.eligibilities[0].isPrimary &&
        isUnderEighteen(member?.dateOfBirth) ? (
          <p className="p-0 text-center font-weight-light">
            Your luxury gym choice is <b>managed by the primary member</b> on
            the account. Please contact the primary member to help with luxury
            gym selection.
          </p>
        ) : (
          <p className="p-0 text-center font-weight-light">
            You can choose a different luxury gym every month or stick with your
            favorite. If you change your luxury gym, it will take effect on the{" "}
            <b>1st of the following month.</b>
          </p>
        )}
      </div>
    );
  };

  const getFooter = () => {
    if (isSpinning) {
      return "";
    }
    if (
      !member?.eligibilities[0].isPrimary &&
      isUnderEighteen(member?.dateOfBirth)
    ) {
      if (hasSelectedGym) {
        return (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="luxury-gym__gym-disclaimer">
              <FontAwesomeIcon icon={faInfoCircle} /> Luxury gym choice is
              managed by the primary member
            </span>
          </div>
        );
      }
      return null;
    }
    if (hasSelectedGym) {
      if (!changeinProgress) {
        return (
          <div className="d-flex flex-column justify-content-center align-items-center">
            {member?.eligibilities[0].isPrimary && (
              <span className="luxury-gym__gym-disclaimer">
                <FontAwesomeIcon icon={faInfoCircle} /> Luxury gym choice for
                underage members is linked to yours
              </span>
            )}
            <button
              onClick={() => setOpenChooseLuxuryGymModal(true)}
              className="btn btn-outline-primary btn-sm luxury-gym__button"
            >
              Change Luxury Gym
            </button>
          </div>
        );
      }
      if (changeinProgress) {
        return (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <button
              onClick={handleDiscardLuxuryGym}
              className="btn btn-outline-primary btn-sm luxury-gym__button luxury-gym__discard"
            >
              <img src={LeftArrowRotate} width="15px" height="21px" /> Discard
              Luxury Gym Change
            </button>
            <DiscardLuxuryGymModal
              show={showDiscardLuxuryGymModal}
              loading={loadingDiscardLuxuryGym}
              onHide={toggleDiscardLuxuryGymModal}
              handleSubmit={() => discardLuxuryGymChange(gymList[0]?.id)}
              gymInfo={{
                effectiveDate: getStartDate(
                  luxuryGyms ? luxuryGyms[numberOfGyms - 1]?.startDate : "ERR"
                ),
                newGymName: gymList[1]?.name,
                currentGymName: gymList[0]?.name,
              }}
            />
            <SomethingWentWrongModal
              show={showSomethingWentWrongModal}
              onCancel={toggleSomethingWentWrongModal}
            />
          </div>
        );
      }
    }
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <button
          onClick={() => setOpenChooseLuxuryGymModal(true)}
          className="btn btn-outline-primary btn-sm luxury-gym__button"
        >
          Choose a Luxury Gym
        </button>
      </div>
    );
  };

  return (
    <>
      <Tile className="widget__card luxury-gym">
        <Tile.THeader>
          <div className="m-2">
            <img src={LuxuryGymPin} width="15px" height="21px" />{" "}
            <b>MY LUXURY GYM</b>
          </div>
        </Tile.THeader>
        <Tile.TBody className="h-auto">{getContent()}</Tile.TBody>
        <Tile.TFooter className="luxury-gym__footer ">
          {getFooter()}
        </Tile.TFooter>
      </Tile>

      {openChooseLuxuryGymModal && (
        <ChooseLuxuryGymModal
          show={openChooseLuxuryGymModal}
          onClose={() => setOpenChooseLuxuryGymModal(false)}
          setOpenChooseLuxuryGymModal={setOpenChooseLuxuryGymModal}
          setShowSuccessToast={setShowSuccessToast}
        />
      )}
    </>
  );
};

export default LuxuryGymTile;
