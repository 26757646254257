import React, { useState, useContext } from "react";
import { Formik, Form as FormikForm } from "formik";
import { UserContext } from "../../../context/UserProvider";
import * as Yup from "yup";
import { Form, Col, Row } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
interface IChangePersonNameValues {
  firstName: string;
  lastName: string;
}

const PersonalInformation = () => {
  const { user: auth0User } = useAuth0();

  const { user } = useContext(UserContext);
  const userInfo = user.AccountSummaryMember;
  const [initialNameChangeValues] = useState<IChangePersonNameValues>({
    firstName: userInfo?.firstName ?? auth0User?.given_name ?? "",
    lastName: userInfo?.lastName ?? auth0User?.family_name ?? "",
  });

  const changePersonNameSchema = Yup.object()
    .shape({
      firstName: Yup.string().required().defined("Please enter a first name."),
      lastName: Yup.string().required().defined("Please enter a last name."),
    })
    .defined();
  return (
    <div className="edit-profile">
      <span>Personal Information</span>
      <Row>
        <Col>
          <Formik
            initialValues={initialNameChangeValues}
            validationSchema={changePersonNameSchema}
            onSubmit={(values, { resetForm }) => {
              const firstName = values.firstName;
              const lastName = values.lastName;
              resetForm({
                values: { lastName: lastName, firstName: firstName },
              });
            }}
          >
            {(props) => (
              <FormikForm
                style={{ marginLeft: "14px" }}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <Row>
                  <Form.Group
                    as={Col}
                    xs={3}
                    sm={3}
                    className="mb-3 p-0 edit-profile__names"
                  >
                    <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                      First Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      id="firstName"
                      onChange={props.handleChange}
                      value={props.values.firstName}
                      className="edit-profile__input"
                      disabled={true}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    xs={3}
                    className="mb-3 p-0 edit-profile__names"
                  >
                    <Form.Label className="form-control-sm col-form-label eligibility-form__label edit-profile__title2 form-label mt-3 p-0">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={props.values.lastName}
                      className="edit-profile__input"
                      onChange={props.handleChange}
                      disabled={true}
                    />
                  </Form.Group>
                </Row>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
      <div className="vp__personal-info my-4" />
    </div>
  );
};

export default PersonalInformation;
