import React, { SetStateAction } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { Dispatch } from "react";
import { Payment } from "../../../types";

const creditCardPaymentSchema = Yup.object().shape({
  creditCardHolderName: Yup.string().min(1).required("Required"),
  creditCardMaskNumber: Yup.string().min(1).max(16).required("Required"),
  creditCardExpirationMonth: Yup.string().min(1).required("Required"),
  creditCardExpirationYear: Yup.date()
    .min(
      new Date().getFullYear(),
      "Year must be current year or greater than current year"
    )
    .required("Required"),
  creditCardPostalCode: Yup.string()
    .min(5, "Zip Code must be 5 digits long")
    .required("Required"),
});

const achPaymentSchema = Yup.object().shape({
  achAccountName: Yup.string().min(1).required("Required"),
  achAccountType: Yup.string().min(1).required("Required"),
  achBankName: Yup.string().min(1).required("Required"),
});

const PaymentForm = ({
  paymentForm,
  onEdit,
  onClose,
}: {
  paymentForm: Payment;
  onEdit: (paymentMethod: Payment) => void;
  onClose: Dispatch<SetStateAction<boolean>>;
}) => {
  const initialValues: Payment = paymentForm;
  const year = new Date().getFullYear() - 20;
  const listOfYears = Array.from(new Array(60), (val, index) => index + year);
  const listOfMonths = Array.from(Array(12).keys(), (val, index) => index + 1);
  const listOfAchAccountTypes = [
    { type: "Checking", displayName: "Checking" },
    { type: "Saving", displayName: "Saving" },
    { type: "BusinessChecking", displayName: "Business Checking" },
  ];

  const getPaymentSchema = () => {
    if (paymentForm.type === "CreditCard") {
      return creditCardPaymentSchema;
    }
    if (paymentForm.type === "ACH") {
      return achPaymentSchema;
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={getPaymentSchema()}
        onSubmit={() => {
          return;
        }}
      >
        {(props) => {
          return (
            <>
              <Modal.Body>
                <Form
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    marginLeft: `10px`,
                  }}
                  placeholder={undefined}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                >
                  {paymentForm.type === "CreditCard" && (
                    <>
                      <label className="payment-label" htmlFor="name">
                        CARDHOLDER NAME <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        style={{
                          border: "solid",
                          borderWidth: "thin",
                          borderColor: "grey",
                        }}
                        className="w-100 rounded mb-2"
                        id="name"
                        name="creditCardHolderName"
                        defaultValue={paymentForm.creditCardHolderName}
                      />
                      <ErrorMessage name="name">
                        {(msg) => (
                          <div
                            style={{
                              fontSize: "0.8rem",
                              marginTop: "0px",
                              color: "red",
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "20px",
                            width: "33%",
                          }}
                        >
                          <label
                            className="payment-label"
                            htmlFor="card.expMonth"
                          >
                            EXP MONTH <span style={{ color: "red" }}>*</span>
                          </label>
                          <ErrorMessage name="card.expMonth" />
                          <Field
                            className="rounded mb-2"
                            style={{
                              width: "50%",
                              border: "solid",
                              borderWidth: "thin",
                              borderColor: "grey",
                            }}
                            as="select"
                            id="card.expMonth"
                            name="creditCardExpirationMonth"
                            defaultValue={parseInt(
                              paymentForm.creditCardExpirationMonth
                            )}
                          >
                            {listOfMonths.map((month) => {
                              return (
                                <option key={month} value={month}>
                                  {month}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "33%",
                          }}
                        >
                          <label
                            className="payment-label"
                            htmlFor="card.expYear"
                          >
                            EXP YEAR <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            className="rounded mb-2"
                            style={{
                              width: "50%",
                              border: "solid",
                              borderWidth: "thin",
                              borderColor: "grey",
                            }}
                            as="select"
                            id="card.expYear"
                            name="creditCardExpirationYear"
                            defaultValue={parseInt(
                              paymentForm.creditCardExpirationYear
                            )}
                          >
                            {listOfYears.map((year) => {
                              return (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                      </div>
                      <label className="payment-label" htmlFor="card.zip">
                        ZIP CODE <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        className="w-100 rounded mb-2"
                        style={{
                          border: "solid",
                          borderWidth: "thin",
                          borderColor: "grey",
                          marginBottom: "0px",
                        }}
                        id="card.zip"
                        name="creditCardPostalCode"
                        defaultValue={paymentForm.creditCardPostalCode}
                      />
                      <ErrorMessage name="card.zip">
                        {(msg) => (
                          <div
                            style={{
                              fontSize: "0.8rem",
                              marginTop: "0px",
                              color: "red",
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </>
                  )}
                  {paymentForm.type === "ACH" && (
                    <>
                      <label className="payment-label" htmlFor="ach.name">
                        E-CHECK ACCOUNT NAME{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        style={{
                          border: "solid",
                          borderWidth: "thin",
                          borderColor: "grey",
                        }}
                        className="w-100 rounded mb-2"
                        id="ach.name"
                        name="achAccountName"
                        defaultValue={paymentForm.achAccountName}
                      />
                      <ErrorMessage name="ach.name">
                        {(msg) => (
                          <div
                            style={{
                              fontSize: "0.8rem",
                              marginTop: "0px",
                              color: "red",
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "20px",
                            width: "35%",
                          }}
                        >
                          <label
                            className="payment-label"
                            htmlFor="ach.accountType"
                          >
                            ACCOUNT TYPE <span style={{ color: "red" }}>*</span>
                          </label>
                          <ErrorMessage name="ach.accountType" />
                          <Field
                            className="rounded mb-2"
                            style={{
                              width: "100%",
                              border: "solid",
                              borderWidth: "thin",
                              borderColor: "grey",
                            }}
                            as="select"
                            id="ach.accountType"
                            name="achAccountType"
                            defaultValue={paymentForm.achAccountType}
                          >
                            {listOfAchAccountTypes.map((type) => {
                              return (
                                <option key={type.type} value={type.type}>
                                  {type.displayName}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "65%",
                          }}
                        >
                          <label
                            className="payment-label"
                            htmlFor="ach.bankName"
                          >
                            BANK NAME <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            className="rounded mb-2"
                            style={{
                              width: "100%",
                              border: "solid",
                              borderWidth: "thin",
                              borderColor: "grey",
                            }}
                            id="ach.bankName"
                            name="achBankName"
                            defaultValue={paymentForm.achBankName}
                          />
                          <ErrorMessage name="ach.bankName">
                            {(msg) => (
                              <div
                                style={{
                                  fontSize: "0.8rem",
                                  marginTop: "0px",
                                  color: "red",
                                }}
                              >
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <div className="row pl-lg-4 pr-lg-4 justify-content-between w-100">
                  <button
                    onClick={() => onClose(false)}
                    className="col btn btn-outline-primary ml-2"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => onEdit(props.values)}
                    className="col mx-2 btn btn-secondary "
                    disabled={!props.isValid && props.dirty === true}
                  >
                    Edit Payment Method
                  </button>
                </div>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default PaymentForm;
