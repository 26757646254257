import { PropsWithChildren, useContext, useState } from "react";
import { Modal, Button, Spinner, ModalProps } from "react-bootstrap";
import axiosInstance from "../../axios.instance";
import { useConfig } from "../../configuration/useConfig";
import { UserContext } from "../../context/UserProvider";
import {
  AccountSubscription,
  AccountSummaryMember,
} from "../../utility/member-portal-api-types";
import showToastMessage from "../../utility/ProfileToast";
import { poll } from "../../utility/util";
import { AccountContext } from "../../context/AccountProvider";

export type OrderAction =
  | {
      ratePlanChargeId: string;
      subscriptionNumber?: string;
      type: "DiscardSubscription";
    }
  | {
      currentRatePlanChargeId: string;
      newRatePlanChargeId: string;
      currentSubscriptionNumber: string;
      type: "SubscriptionUpdate";
    };

export async function discardSubscription(
  personId: number,
  subscription: AccountSubscription,
  isPayroll: boolean,
  isDependent: boolean | undefined,
  subscriptionCurrent?: AccountSubscription
) {
  const orderAction: Partial<OrderAction> = {
    type: "DiscardSubscription",
    ratePlanChargeId: subscriptionCurrent
      ? subscriptionCurrent.ratePlanChargeId
      : subscription.ratePlanChargeId,
  };

  if (!isPayroll) {
    if (!subscription.subscriptionNumber && !isDependent) {
      throw new Error(
        "A subscriptionNumber is required when discarding a subscription change as a non payroll member."
      );
    }

    orderAction.subscriptionNumber = subscription.subscriptionNumber;
  }

  return new Promise((resolve, reject) => {
    axiosInstance
      .put("/account/subscriptions", {
        members: [
          {
            personId,
            orderActions: [orderAction],
          },
        ],
      })
      .then((response) => {
        response.data
          ? resolve(
              `/account/subscriptions/status/${response.data.executionId}`
            )
          : reject("Error");
      });
  });
}

function Element({ children, ...props }: PropsWithChildren<ModalProps>) {
  return (
    <Modal show {...props} centered>
      <Modal.Header
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <Modal.Title>
          <h2>Discard Cancellation?</h2>
          {props.onHide && (
            <span style={{ cursor: "pointer" }} onClick={props.onHide}>
              X
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      {children}
    </Modal>
  );
}

interface DiscardCancellationProperties {
  member: AccountSummaryMember;
  onClose: (success?: boolean) => void;
  hasUnderageDependents?: boolean;
  isLoadingDependents?: boolean;
}

const DiscardCancellationModal = ({
  member,
  onClose,
  hasUnderageDependents = false,
  isLoadingDependents = false,
}: DiscardCancellationProperties) => {
  const [isDiscarding, setIsDiscarding] = useState(false);

  const { client } = useConfig();
  const { user } = useContext(UserContext);
  const { refresh } = useContext(AccountContext);
  const pendingCancellation = member?.eligibilities?.find(
    (e) => e.isPendingTermination
  );
  const termDate = pendingCancellation?.memberTermDate;
  const subscription = pendingCancellation?.currentSubscription;

  const personId = member?.personId;

  const isDependent = !member.eligibilities[0].isPrimary;

  const isPayroll = user.isPayroll;
  if (!(termDate && subscription && personId && isPayroll !== undefined)) {
    return <></>;
  }
  function validate(res) {
    if ((res.data.status as unknown as string) == "SUCCESS") {
      refresh().then(() =>
        showToastMessage("Your account was successfully updated", true, client)
      );
      onClose(true);
      return true;
    } else if ((res.data.status as unknown as string) == "FAILED") {
      refresh().then(() =>
        showToastMessage(
          "There was an error with your plan update.",
          false,
          client
        )
      );
      onClose(false);
      return false;
    } else if ((res.data.status as unknown as string) == "PROCESSING") {
      throw res;
    }
  }
  const onDiscard = async () => {
    try {
      setIsDiscarding(true);
      await discardSubscription(
        personId,
        subscription,
        isPayroll,
        isDependent
      ).then((url) => {
        poll(() => axiosInstance.get(`${url}`).then(validate), 45, 3000);
      });
    } catch (e) {
      console.log(e);
      onClose(false);
    }
  };

  if (isDiscarding) {
    return (
      <Element style={{ pointerEvents: "none" }}>
        <Modal.Body style={{ minHeight: "175px" }}>
          <div className="d-flex flex-column justify-content-center">
            <div className="center-loading" style={{ marginTop: "0" }}>
              <Spinner animation="border" />
            </div>
            <span className="text-center mt-3">
              Processing... This may take a minute.
            </span>
            <p className="text-center">Please do not refresh the page.</p>
          </div>
        </Modal.Body>
      </Element>
    );
  }

  return (
    <Element onHide={() => onClose()}>
      <Modal.Body>
        {isLoadingDependents ? (
          <>
            <p className="text-center mb-3">Please wait...</p>
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          </>
        ) : (
          <div>
            <p>
              Our records indicate that you recently canceled your current
              fitness package, which is scheduled to take effect{" "}
              <b>on {new Date(`${termDate}Z`).toLocaleDateString()}</b>. Are you
              sure you want to discard the cancellation?
            </p>
            {hasUnderageDependents && (
              <p style={{ paddingTop: "1rem" }}>
                <b>Note</b>: Discarding this cancellation will also discard the
                pending cancellation for family members under 18.
              </p>
            )}
          </div>
        )}
      </Modal.Body>
      {!isLoadingDependents && (
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "white", width: "48%" }}
            onClick={() => onClose()}
          >
            <span style={{ color: "var(--primary)" }}>Close</span>
          </Button>
          <Button
            variant="primary"
            style={{ width: "48%" }}
            onClick={onDiscard}
          >
            Discard Cancellation
          </Button>
        </Modal.Footer>
      )}
    </Element>
  );
};

export default DiscardCancellationModal;
