import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Spinner } from "react-bootstrap";
import { Product } from "../../../api/enrollment/Queries";
import YellowLaptop from "../../../resources/YellowLaptopDigitalPackage.svg";
import useGetScreenSize from "../../../hooks/useGetScreenSize";
import { useConfig } from "../../../configuration/useConfig";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const AdditionalPackageCard = ({
  productInfo,
  name,
  price,
  handleChange,
  updateTier,
  selected,
  loading,
  isPayroll,
}: {
  productInfo?: Product;
  name: string;
  className: string;
  price: number;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updateTier?: (Product: Product) => void;
  selected: string;
  loading: boolean;
  isPayroll: boolean;
  isIndividualStudioPurchaseAllowed: boolean;
}) => {
  const { isSmallScreen } = useGetScreenSize();
  const { config } = useConfig();

  return (
    <>
      {loading ? (
        <div className="center-loading">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {productInfo && !isSmallScreen ? (
            <label htmlFor={productInfo?.name}>
              <div
                className={`digital-content-only-container ${
                  !isPayroll
                    ? "digital-content-only-container__non-payroll"
                    : ""
                } mt-3`}
                style={
                  config["client"] === "mutual-of-omaha"
                    ? { marginLeft: "-75px" }
                    : {}
                }
              >
                <div className="digital-content-only-container__content-row">
                  <div className="digital-content-only-container__description-container">
                    <>
                      <div
                        className={`digital-content-only-container__description${
                          isPayroll ? "__payroll" : ""
                        }`}
                      >
                        <div className="digital-content-only-container__description-middle-line">
                          {productInfo?.description}
                        </div>
                      </div>
                      {config["client"] === "mutual-of-omaha" && (
                        <div
                          style={{
                            color: "#0071B3",
                            fontSize: "12px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          }}
                        >
                          <span>
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </span>{" "}
                          This package does not include gym or digital access
                        </div>
                      )}
                    </>
                  </div>
                  <div
                    className="check-button-digital-wrapper"
                    style={{ width: `50%` }}
                  >
                    <div className="check-button-digital">
                      <label
                        className="btn btn-default"
                        style={
                          selected === name
                            ? {
                                borderColor:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#21445C"
                                    : "#449D44",
                                backgroundColor:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#21445C"
                                    : "",
                                color:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#FFFFFF"
                                    : "",
                              }
                            : {
                                borderColor:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#21445C"
                                    : "#0071dc",
                                color:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#21445C"
                                    : "",
                              }
                        }
                      >
                        <input
                          type="radio"
                          id={name}
                          value={name}
                          name="package.sku"
                          checked={selected === name}
                          onChange={(e) => {
                            handleChange(e);
                            updateTier ? updateTier(productInfo) : null;
                          }}
                        />
                        {selected === name ? (
                          <span
                            style={{
                              color:
                                config["client"] === "mutual-of-omaha"
                                  ? "#FFFFFF"
                                  : "#449D44",
                              whiteSpace: `pre`,
                            }}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              style={{
                                color:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#FFFFFF"
                                    : "",
                              }}
                            />{" "}
                            Selected
                          </span>
                        ) : (
                          <span>Select</span>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          ) : productInfo && isSmallScreen ? (
            <label htmlFor={name} className="package-card-label">
              <Card
                className={`card ${
                  productInfo?.isBestValue ? "best-value" : ""
                }`}
              >
                <Card.Body className="body">
                  <Card.Title>{name}</Card.Title>
                  <Card.Text style={{ textAlign: "center" }}>
                    <div className="mb-0 p-0 price">${price}</div>
                    <div className="p-0 per-month">
                      {isPayroll ? "per paycheck" : "/ month"}
                    </div>
                    <div className="check-button">
                      <label
                        className="btn btn-default"
                        style={
                          selected === name
                            ? {
                                borderColor:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#21445C"
                                    : "#449D44",
                                backgroundColor:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#21445C"
                                    : "",
                                color:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#FFFFFF"
                                    : "",
                              }
                            : {
                                borderColor:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#21445C"
                                    : "#0071dc",
                                color:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#21445C"
                                    : "",
                              }
                        }
                      >
                        <input
                          type="radio"
                          id={name}
                          value={name}
                          name="package.sku"
                          checked={selected === name}
                          onChange={handleChange}
                        />
                        {selected === name ? (
                          <span
                            style={{
                              color:
                                config["client"] === "mutual-of-omaha"
                                  ? "#FFFFFF"
                                  : "#449D44",
                              whiteSpace: `pre`,
                            }}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              style={{
                                color:
                                  config["client"] === "mutual-of-omaha"
                                    ? "#FFFFFF"
                                    : "",
                              }}
                            />{" "}
                            Selected
                          </span>
                        ) : (
                          <span>Select</span>
                        )}
                      </label>
                    </div>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <div
                    style={{
                      width: `100%`,
                      background: `#ffffff`,
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                      paddingTop: "10px",
                    }}
                  >
                    <div className="feature-package-select__gym-count-container">
                      <img src={YellowLaptop} alt="Yellow Laptop Icon" />
                    </div>
                    <hr style={{ marginLeft: `10%`, marginRight: `10%` }} />
                    {config["client"] === "hcsc" && (
                      <div className="feature-package-select__studio-class-reward">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            paddingTop: `3%`,
                          }}
                        >
                          <span>
                            `` Pay-As-You-Go access to studio classes. 30% off
                            every 10th class
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </Card.Footer>
              </Card>
            </label>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default AdditionalPackageCard;
